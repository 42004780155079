// Function to add our give data into cache
export const addDataIntoCache = (cacheName, url, response) => {
  // Converting our response into Actual Response form
  const data = new Response(JSON.stringify(response))

  if ('caches' in window) {
    // Opening given cache and putting our data into it
    caches.open(cacheName).then((cache) => {
      cache.put(url, data)
      // alert('Data Added into cache!')
    })
  }
}


// Function to get all cache data
export const getDataFromCache = async (cacheName, url) => {
  // List of all caches present in browser
  // var names = await caches.keys()

  // Opening that particular cache
  const cacheStorage = await caches.open(cacheName)

  // Fetching that particular cache data
  const cachedResponse = await cacheStorage.match(url)
  if(cachedResponse !== undefined) {
    return await cachedResponse.json()
  }
  return undefined
}


export const deleteFromCache = async (cacheName) => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      if(name === cacheName){
        caches.delete(name)
      }
    })
  })
}


export const deleteEntireCache = async () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      if(name === 'myFalTeam' || 
          name === 'myFalPredictions' || 
          name === 'falParameters'){
        caches.delete(name)
      }
    })
  })
}