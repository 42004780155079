import predictionConfig from '../config/predictionConfig.json'

const predConfig = predictionConfig.config

export const config = {
  "epsilon": predConfig.epsilon,
  "minScoreDelta": predConfig.minScoreDelta,
  "defaultScore": predConfig.defaultScore,
  "defaultValues": predConfig.defaultValues,

  "defaultDegree": predConfig.defaultDegree,
  "defaultScale": predConfig.defaultScale,
  
  "minScore": predConfig.minScore,
  "maxScore": predConfig.maxScore,

  "minDegree": predConfig.minDegree,
  "maxDegree": predConfig.maxDegree,
  "stepDegree": predConfig.stepDegree,

  "minXShift": predConfig.minXShift,
  "maxXShift": predConfig.maxXShift,
  "stepX": predConfig.stepX,
  
  "minYShift": predConfig.minYShift,
  "maxYShift": predConfig.maxYShift,
  "stepY": predConfig.stepY,

  "minScale": predConfig.minScale,
  "maxScale": predConfig.maxScale,
  "stepScale": predConfig.stepScale,

  "minFinalXShift": predConfig.minFinalXShift,
  "maxFinalXShift": predConfig.maxFinalXShift,
  "minFinalYShift": predConfig.minFinalYShift,
  "maxFinalYShift": predConfig.maxFinalYShift
}