import { Container } from '@mui/material'
import {
  Routes,
  Route
} from "react-router-dom"

import Home from './components/Home'
import Shows from './components/Shows'
import Team from './components/Team'
import Predictions from './components/Predictions'
import About from './components/About'
import Header from './components/Header'

const App = () => (
  <div>
  <Header position="sticky"  style={{boxShadow: "none"}}/>
  <Container maxWidth="xl">
    <Routes>
      <Route path="/shows/:id" element={<Shows />} />
      <Route path="/team" element={<Team />} />
      <Route path="/predictions" element={<Predictions />} />
      <Route path="/about" element={<About />} /> 
      <Route path="/" element={<Home />} />
    </Routes>
  </Container>
  </div>
)
export default App