import { Container, Button } from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import {GRAPHTYPES, TYPECOLORS} from './../util/enum'
import { useParams, useMatch } from "react-router-dom"
import { Line } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'
import { assert, dateDiffInDays, formatToDate, formatDateToStr, getDaysArray } from '../util/utilityFunctions'
import { allFalAwardDatesSet, getAllFalAwardDatesDic, ruleDict } from '../util/falRuleWrapper'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
)


const Graph = ({ graphType, content, refLine }) => {
  // Note: every useState must come before any return statement
  const chartRef = useRef(null)

  // First, check parameter tempGraphType to the enum GRAPHTYPE for safety
  if(graphType !== GRAPHTYPES.score &&
    graphType !== GRAPHTYPES.watchers &&
    graphType !== GRAPHTYPES.dropped &&
    graphType !== GRAPHTYPES.favorites &&
    graphType !== GRAPHTYPES.posts) {
      console.error("Error: graphType unknown", graphType)
  }

  // If content error exists, handle it
  if(content === undefined){
    return (<div>Error: Content Undefined</div>)
  } else if (content.stats.length === 0) {
    return (<div>Loading content...</div>)
  }

  const tempGraphType = graphType === 'score' ? 'mean' : graphType
  const stats = content.stats

  // Function to convert date object into str for x-axis labels.
  // Adds on the year if it's the first date or year has changed
  const convertToXLabels = (dateArr) => {
    return dateArr.map((date, idx) => {
      let output = `${date.getMonth()+1}/${date.getDate()}`
      if(idx === 0 || date.getFullYear() !== dateArr[idx-1].getFullYear()){
        output += '/'+ date.getFullYear()
      }
      return output
    })
  } 

  const insertWeekNumberIntoDateStr = (labels) => {
    // Insert 'week #' into xLabels
    return labels.map((dateStr) => {
      if(allFalAwardDatesDic[dateStr] !== undefined){
        return dateStr + ` (w${allFalAwardDatesDic[dateStr]})`
      } 
      return dateStr
    })
  }

  let refLineDates = []
  let totalDatesArr = [] // Date objects

  // Add on prediction dates to formatted dates
  if(refLine !== undefined){
    // generate all days from start to finish of FAL
    const firstShowDate = content.dates[0]
    const lastShowDate = content.dates[content.dates.length-1]
    const firstFalDate = refLine.dates[0]
    const lastFalDate = refLine.dates[refLine.dates.length-1]

    const firstDate = Math.min(firstShowDate ?? firstFalDate, firstFalDate)
    const lastDate = Math.max(lastShowDate ?? lastFalDate, lastFalDate)

    totalDatesArr = getDaysArray(firstDate, lastDate)
    // const refLineDatesArr = getDaysArray(firstFalDate, lastFalDate)
    // refLineDates = convertToXLabels(refLineDatesArr, true)
  } 

  // Highlight FAL award dates
  const allFalAwardDatesDic = refLine !== undefined 
    ? getAllFalAwardDatesDic(totalDatesArr[0])
    : undefined

  let xLabels = undefined
  if(refLine !== undefined) {
    xLabels = insertWeekNumberIntoDateStr(
                convertToXLabels(totalDatesArr)
              )
  } else {
    const contentDateObjectArray = content.stats.map(entry => {
      const strDate = entry["date"]["N"]
      return new Date(strDate.substring(0,4), 
                      strDate.substring(4,6)-1, 
                      strDate.substring(6,8))
    })
    xLabels = convertToXLabels(contentDateObjectArray)
  }

  // yLabels is for the true data only
  let yLabels = []
  if(refLine !== undefined){
    totalDatesArr.forEach((dateObj, idx) => {
      const stat = stats[dateObj]
      if(stat !== undefined){
        // Don't add y values less than 0
        if(stat <= 0) {
          return
        }
        yLabels.push({x: xLabels[idx], y: stat})  
      }
    })
  } else {
    yLabels = stats.map(p => {
      return p[tempGraphType]["N"]
    }) 
  }

  // Get min and max Y data points
  let minYData = 0, maxYData = 0
  let minYDataFit = 0, maxYDataFit = 0
  // Sandwich between min and max values (bandpass)
  if(refLine !== undefined) {
    // Condition for when graph has no real data points
    if(Object.keys(stats).length === 0){
      minYData = Math.min.apply(Math, refLine[graphType].map(obj => 
        obj.y === 0 ? 0 : obj.y))
      maxYData = Math.max.apply(Math, refLine[graphType].map(obj => 
        obj.y))
      
      minYDataFit = minYData
      maxYDataFit = maxYData
    } else {
      minYData = Math.min(
        Math.min.apply(Math, refLine[graphType].map(obj => 
          obj.y === 0 ? Infinity : obj.y
        )), Math.min.apply(Math, Object.values(stats))
      )
      maxYData = Math.max(
        Math.max.apply(Math, refLine[graphType].map(obj => obj.y)),
        Math.max.apply(Math, Object.values(stats))
      )
      
      minYDataFit = Math.min.apply(Math, Object.values(stats))
      maxYDataFit = Math.max.apply(Math, Object.values(stats))
    }
  } 
  else if(stats.length !== 0) {
    minYData = Math.min.apply(Math, stats.map(obj => 
      obj[tempGraphType]["N"]))
    maxYData = Math.max.apply(Math, stats.map(obj => 
      obj[tempGraphType]["N"]))
  }

  // Minimum value for tolerance is 0.1. Otherwise graph would collapse
  const tolerance = refLine !== undefined
                      ? Math.max((maxYDataFit - minYDataFit) * 0.1, 0.1)
                      : Math.max((maxYData - minYData)*0.1, 0.1)
  
  // Options for chartjs 
  const graphScale = { min: minYData-tolerance,
    max: maxYData+tolerance }

  const scoreZoomLimits = { min: 0, max: 10+0.5, minRange: 0.1 }
  const otherZoomLimits = { min: 0, max: maxYData+50, minRange: 10}
  // minYData-50
  
  const yOptions = {
    type: graphScale,
    zoom: graphType === GRAPHTYPES.score ? scoreZoomLimits : otherZoomLimits,
  }

  const graphLabel = graphType.charAt(0).toUpperCase() + graphType.slice(1)

  const options = {
    responsive: true,
    scales: {
      y: yOptions.type
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' 
      },
      title: {
        display: true,
        text: `${graphLabel} Graph of "${content.title}"`,
      },
      zoom: {
        limits: {
          x: {min: -200, max: 200, minRange: 50},
          y: yOptions.zoom
        },
        pan: {
          enabled: true,
          onPanStart({chart, point}) {
            const area = chart.chartArea
            const w25 = area.width * 0.01
            const h25 = area.height * 0.01
            if (point.x < area.left + w25 || point.x > area.right - w25
              || point.y < area.top + h25 || point.y > area.bottom - h25) {
              return false; // abort
            }
          },
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
        }
      }
    }
  }

  const plugin = {
    id: "custom_canvas_background_color",
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext("2d")
      ctx.save()

      if(refLine !== undefined) {
        const xAxis = chart.scales['x']
        const yAxis = chart.scales['y']

        // Draw 85K line for watchers
        if(graphType === GRAPHTYPES['watchers']) {
          const y = yAxis.getPixelForValue(ruleDict.maxMembers)
          ctx.beginPath()
          ctx.moveTo(xAxis.left, y)
          ctx.lineTo(xAxis.right, y)
          ctx.lineWidth = 1
          ctx.strokeStyle = 'blue'
          ctx.setLineDash([5, 15])
          ctx.stroke()
        }

        // Draw vertical line when FAL ends
        const x = xAxis.getPixelForValue(
          formatDateToStr(ruleDict.falAwardEndDate))
        ctx.beginPath()
        ctx.moveTo(x, yAxis.top) 
        ctx.lineTo(x, yAxis.bottom)
        ctx.lineWidth = 1
        ctx.strokeStyle = 'red'
        ctx.setLineDash([5, 15])
        ctx.stroke()
      }
      // Get white background for when image is copied
      ctx.globalCompositeOperation = "destination-over"
      ctx.fillStyle = "white"
      ctx.fillRect(0, 0, chart.width, chart.height)
      ctx.restore()
    }
  }

  const pointBackgroundColor = [], pointBorderColor = []
  yLabels.forEach((val, idx) => {
    if(val === yLabels[idx-1] && val === yLabels[idx-1]) {
      pointBackgroundColor.push('transparent')
      pointBorderColor.push('transparent')
    } else {
      pointBackgroundColor.push(TYPECOLORS[graphType])
      pointBorderColor.push(TYPECOLORS[graphType])
    }
  })

  // xlabels are string of X AXIS only!!
  const data = {
    labels: xLabels,
    datasets: [
      {
        label: "Current " + graphLabel,
        data: yLabels,
        borderColor: TYPECOLORS[graphType],
        backgroundColor: TYPECOLORS[graphType].slice(0, -1) + ', 0.5)',
        pointBackgroundColor: pointBackgroundColor,
        pointBorderColor: pointBorderColor
      }
    ]
  }

  if(refLine !== undefined) {
    // Matches refLine x coord string dates with falAward dates
    const refLinePointBorderColor = 
      refLine[graphType].map(coord => 
        allFalAwardDatesDic[coord.x] !== undefined
          ? 'red' : 'transparent'
      )

    // Insert Week Number into refLine coord x
    const refLineWithWeekNum = refLine[graphType].map((coord) => {
      if(allFalAwardDatesDic[coord.x] !== undefined){
        const dateWithWeekNum = `${coord.x} (w${allFalAwardDatesDic[coord.x]})`
        return {...coord, x: dateWithWeekNum}
      }
      return coord
    })

    data.datasets.push({
      data: refLineWithWeekNum,
      pointBorderColor: refLinePointBorderColor,
      label: "Prediction"
    })
  }

  // Button to switch graph between zooming in and out
  const handleZoomFit = (event) => {
    event.preventDefault()    

    // Guard against no real data on graph
    if(!isFinite(maxYDataFit) || !isFinite(minYDataFit)){
      return
    }
    assert(!isNaN(maxYData), 'maxY is nan in graph.js')

    //Create a tolerance of 10% of the difference
    let tolerance = (maxYData - minYData) * 0.1

    const zoom = {min: minYData-tolerance, max: maxYData+tolerance}
    if(refLine !== undefined){
      tolerance = (maxYDataFit - minYDataFit) * 0.1
      zoom['min'] = minYDataFit-tolerance
      zoom['max'] = maxYDataFit+tolerance
      assert(!isNaN(maxYDataFit), 'maxY is nan in graph.js')
    }
    chartRef.current.zoomScale('y', zoom)
  }

  const handleResetZoom = (event) => {
    event.preventDefault()
    chartRef.current.resetZoom()
  }

  return (
    <div>
      <Button onClick={handleZoomFit}>Zoom Fit</Button>
      <Button onClick={handleResetZoom}>Reset Zoom</Button>
      <Line ref={chartRef} options={options} data={data} id='graph' plugins={[plugin]}/>
    </div>
  )
}
export default Graph