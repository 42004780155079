const tableSort = (arr, orderBy, param) => {
  switch(orderBy) {
    case 'asc':
    default:
      return arr.sort((a,b) => 
        a[param] > b[param] ? 1 : b[param] > a[param] ? -1 : 0
      )
    case 'desc':
      return arr.sort((a,b) => 
        a[param] < b[param] ? 1 : b[param] < a[param] ? -1 : 0
      )
  }
}
export default tableSort