import servers from  './../data/servers'
import getTable from './serverFunctions'

const falAnimeTable = servers.fal_anime
const falAnimeDataTable = servers.fal_anime_data


const localCalcPoints = (show) => {
  let weekPts = parseInt(show.weekly_watching_pts["N"])
  weekPts += parseInt(show.weekly_completed_pts["N"])
  weekPts += parseInt(show.weekly_dropped_pts["N"])
  weekPts += parseInt(show.weekly_discussion_pts["N"])
  weekPts += parseInt(show.weekly_score_pts["N"])
  weekPts += parseInt(show.weekly_favorites_pts["N"])

  let totalPts = parseInt(show.total_watching_pts["N"])
  totalPts += parseInt(show.total_completed_pts["N"])
  totalPts += parseInt(show.total_dropped_pts["N"])
  totalPts += parseInt(show.total_discussion_pts["N"])
  totalPts += parseInt(show.total_score_pts["N"])
  totalPts += parseInt(show.total_favorites_pts["N"])
  
  return [weekPts, totalPts]
}


export const fetchFalEntries = async (season) => {
  // Get every show of season from fal_anime table - query
  const data1 = await getTable(falAnimeTable, {"season": String(season)})
  const falAnimeArr = JSON.parse(data1.body).Items
  return falAnimeArr.map(show => {
    return {
      id: parseInt(show.mal_id["N"]),
      title: show.name["S"]
    }
  })
}


export const fetchFalData = async (week, season) => {
  // Get every show of season from fal_anime table - query
  const data1 = await getTable(falAnimeTable, {"season": String(season)})
  if(data1.body === "{\"Items\":[],\"Count\":0,\"ScannedCount\":0}"){
    console.error(`season ${season} is not in the database. Returning empty array`)
    return []
  }
  const falAnimeArr = JSON.parse(data1.body).Items
  const falAnimeArrIds = falAnimeArr.map(item => item.mal_id["N"])

  // Get last week from season of fal_anime_data table - query
  const data2 = await getTable(falAnimeDataTable, {
      "mal_id": falAnimeArrIds,
      "week_season": String(week).concat('-', String(season))
  })
  const falAnimeDataArr = JSON.parse(data2.body).Responses.fal_anime_data
  const sortedData = falAnimeDataArr.map(show => {
    const entry = falAnimeArr.find(e => e.mal_id["N"] === show.mal_id["N"])      
    const [weekPts, totalPts] = localCalcPoints(show)

    return {
      id: parseInt(show.mal_id["N"]),
      title: entry.name["S"], 
      ranking: 0,
      weekly: weekPts,
      total: totalPts
    }
  })

  // Then get rankings
  sortedData.sort((a,b) => (a.total < b.total) ? 1 : -1)
  sortedData.forEach((show, idx, d) => {
    if(idx === 0) {
      show.ranking = 1
    } else if(idx !== 0 && d[idx-1].total !== show.total){
      show.ranking = idx+1
    } else {
      show.ranking = d[idx-1].ranking
    }
  })
  return sortedData
}