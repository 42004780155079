import { Container, AppBar, Toolbar, Button, shadows, Typography, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  useNavigate,
} from "react-router-dom"
import servers from './../data/servers'
import getTable from './../util/serverFunctions'


const Header = () => {
  const navigate = useNavigate()

  // Get Database
  useEffect(() => {
    console.log("Welcome Header")
  },[])

  const homeButtonStyle = {
    backgroundColor: "DodgerBlue",
    color: "white",
    padding: "15px",
    fontFamily: ".roboto-medium",
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#ffffff'
    }
  }
  const buttonStyle = {
    color: "black",
    padding: "10px",
    fontFamily: ".roboto-medium",
  }
  const headerStyle = {
    backgroundColor: "#D9D9D9",
  }

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar style={headerStyle}>
        <Typography variant="h5" component="h3">
          <Button style={homeButtonStyle} onClick={() => navigate(`/`)}>Anistonks</Button>
          <Button style={buttonStyle} onClick={() => navigate(`/team`)}>Team</Button>
          <Button style={buttonStyle} onClick={() => navigate(`/predictions`)}>Predictions</Button>
          <Button style={buttonStyle} onClick={() => navigate(`/about`)}>About</Button>
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default Header