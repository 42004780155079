import { Container, AppBar, Toolbar, Button, IconButton  } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  useParams,
  useMatch,
  Link,
  useLocation
} from "react-router-dom"
import {GRAPHTYPES, TYPECOLORS} from '../util/enum'
import Graph from './Graph'
import servers from '../data/servers'
import getTable from './../util/serverFunctions'


const Shows = () => {
  const location = useLocation()

  const [content, setContent] = 
    useState({title: '', id: 0, stats: []})
  const [thisShow, setThisShow] = useState([])
  const [thisShowStats, setThisShowStats] = useState([])
  const [graphType, setGraphType] = useState('score')
  
  const animeTable = servers.anime
  const animeStatsTable = servers.anime_stats

  // Get Database
  useEffect(() => {
    console.log("Welcome to show page")

    const fetchData = async () => {
      const showId = location.pathname.replace('/shows/', '')
      let title = ''
      if(location.state){
        setThisShow(location.state) //Carry state from search
        title = location.state.name["S"]
      } else {
        console.log("content will be searched")

        // Get one entry from anime - getItem
        const data2 = await getTable(animeTable, {"id": String(showId)})
        const animeArr = JSON.parse(data2.body).Item
        title = animeArr.name["S"]
        // TODO: Temporary chooses first found show. Allow users to choose
        setThisShow(animeArr[0])
      }

      // Get multiple entries from anime_stats - query
      const data1 = await getTable(animeStatsTable, {"id": String(showId)})
      const animeStatsArr = JSON.parse(data1.body).Items
      setThisShowStats(animeStatsArr[0])

      const tempContent = {
        title: title,
        id: showId,
        stats: animeStatsArr
      }
      setContent(tempContent)
    }

    fetchData()
  },[])

  if(content === undefined){
    return <div>Content undefined</div>
  }

  const stats = content.stats
  const latestData = (stats !== undefined && stats.length !== 0) ?
    stats[stats.length-1] : 0
  const malLink = "https://myanimelist.net/anime/" + String(content.id)

  const scoreStyle = {
    backgroundColor: TYPECOLORS[GRAPHTYPES.score],
    color: "white",
    padding: "15px",
    fontFamily: "Arial"
  }
  const watchersStyle = {
    backgroundColor: TYPECOLORS[GRAPHTYPES.watchers],
    color: "white",
    padding: "15px",
    fontFamily: "Arial"
  }
  const droppedStyle = {
    backgroundColor: TYPECOLORS[GRAPHTYPES.dropped],
    color: "white",
    padding: "15px",
    fontFamily: "Arial"
  }
  const favoritesStyle = {
    backgroundColor: TYPECOLORS[GRAPHTYPES.favorites],
    color: "white",
    padding: "15px",
    fontFamily: "Arial"
  }
  const postsStyle = {
    backgroundColor: TYPECOLORS[GRAPHTYPES.posts],
    color: "white",
    padding: "15px",
    fontFamily: "Arial"
  }
  const toMalStyle = {
    color: "white",
    padding: "15px",
    fontFamily: "Arial"
  }

  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <Button style={scoreStyle} onClick={() => 
            setGraphType(GRAPHTYPES.score)}>
            Score
          </Button>     
          <Button style={watchersStyle} onClick={() => 
            setGraphType(GRAPHTYPES.watchers)}>
            Watchers
          </Button>  
          <Button style={droppedStyle} onClick={() => 
            setGraphType(GRAPHTYPES.dropped)}>
            Dropped
          </Button>  
          <Button style={favoritesStyle} onClick={() => 
            setGraphType(GRAPHTYPES.favorites)}>
            Favorites
          </Button>  
          <Button style={postsStyle} onClick={() => 
            setGraphType(GRAPHTYPES.posts)}>
            Posts/Discussions
          </Button>   
          <Link to={malLink} style={toMalStyle} >
            To MAL page
          </Link>          
        </Toolbar>
      </AppBar>

      {/* Title, current score, and link to MAL page */}
      <div>
        <h1 style={{display: 'inline-block'}}>
          {content.title.replaceAll('_',' ')}
        </h1>
        {latestData && 
          <h2 style={{display: 'inline-block', marginLeft: '1rem'}}>
            Score {latestData.mean["N"]}
          </h2>
        }
      </div>
      {content.stats && 
        <Graph graphType={graphType} content={content} />
      }
    </Container>
  )
}
export default Shows