export const GRAPHTYPES = {
  score: 'score',
  watchers: 'watchers',
  dropped: 'dropped',
  favorites: 'favorites',
  posts: 'posts'
}


export const TEAMSTATUS = {
  none: 'none',
  active: 'active',
  bench: 'bench'
}


export const REGRESSIONTYPES = {
  fractional: 'fractional'
}


export const STRATEGYSTATUS = {
  active: 'active',
  bench: 'bench',
  ace: 'ACE',
  activeAndAce: 'active & ACE'
}


// Change color of graph according to graphType
export const TYPECOLORS = {
  score:'rgb(255, 99, 132)',
  watchers:'rgb(100, 149, 237)',
  dropped:'rgb(34, 139, 34)',
  favorites:'rgb(186, 85, 211)',
  posts:'rgb(255, 165, 0)'
}