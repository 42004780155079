import { useEffect, useState } from 'react'
import {
  Button,
  Container, 
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import {
  Link,
  useNavigate
} from "react-router-dom"
// import copyTable from './../util/copyTable'
import tableSort from './../util/tableSort'
import {fetchFalData} from './../util/serverInterface'
import servers from  './../data/servers'
import { ruleDict, thisWeekNumber } from './../util/falRuleWrapper'
import { dateDiffInDays } from './../util/utilityFunctions'


const Home = () => {
  const [orderedData, setOrderedData] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [orderDirection, setOrderDirection] = useState('desc')
  const [isShowRanking, setIsShowRanking] = useState(true)
  const [seasonSelect, setSeasonSelect] = useState(0)

  const navigate = useNavigate()
  const maxTeamNum = ruleDict.teamSize
  const falStartDate = ruleDict.falStartDate
  const today = new Date()
  const startDate = ruleDict.falAwardStartDate
  const seasonNum = ruleDict.seasonNum
  const allSeasons = ruleDict.allSeasons
  const currentYear = ruleDict.year
  const currentSeason = ruleDict.season

  useEffect(() => {
    console.log("Welcome Home")
    
    const fetchData = async () => {
      const fetchedData = await fetchFalData(
          Math.max(thisWeekNumber-1,0), seasonNum)
      let prevWeekData = undefined
      if(fetchedData.length !== 0){
        setOrderedData(fetchedData)
      } else {
        console.log(`No data has been fetched for week 
          ${Math.max(thisWeekNumber-1,0)}, season${seasonNum}`)
        if(thisWeekNumber-2 >= 0){
          prevWeekData = await fetchFalData(thisWeekNumber-2, seasonNum)
          setOrderedData(prevWeekData)
        }
      }
      setDataLoaded(true)
    }
    fetchData()
  },[])

  const handleSortRequest = (event) => {
    setOrderedData(tableSort(orderedData, orderDirection, event))
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')
  }

  const handleSelectTeam = (event) => {
    if(orderedData.length !== 0) {
      navigate('/team',  {state: orderedData})
    }
  }

  const handleSeasonSelect = (event) => {
    setSeasonSelect(event.target.value)
  }

  const tableRank = (
    <Table id="table">
      <TableHead>
        <TableRow>
          <TableCell onClick={() => handleSortRequest('ranking')}>
            <TableSortLabel active={true} direction={orderDirection}>
              Rank
            </TableSortLabel>
          </TableCell>
          <TableCell onClick={() => handleSortRequest('title')}>
            <TableSortLabel active={true} direction={orderDirection}>
              Title
            </TableSortLabel>
          </TableCell>
          <TableCell onClick={() => handleSortRequest('weekly')}>
            <TableSortLabel active={true} direction={orderDirection}>
              Weekly Pts
            </TableSortLabel>
          </TableCell>
          <TableCell onClick={() => handleSortRequest('total')}>
            <TableSortLabel active={true} direction={orderDirection}>
              Total Pts
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderedData.map(show => (
          <TableRow key={show.id}>
            <TableCell>{show.ranking}</TableCell>
            <TableCell>
              <Link to={`/shows/${show.id}`}>
                {show.title.replaceAll('_',' ')}
              </Link>
            </TableCell>
            <TableCell>{show.weekly}</TableCell>
            <TableCell>{show.total}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  return (
    <Container maxWidth="xl">
      <h1>Anistonks</h1>
      <h3>Generates 100% BEST strategy for FAL given correct predictions</h3>

      <h2>Instructions</h2>
      <h3>1. Select Team</h3>
      <h3>2. Tune Predictions</h3>
      <h3>3. Set Parameters</h3>
      
    </Container>
  )
}
export default Home