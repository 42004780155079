import { GRAPHTYPES, TEAMSTATUS } from './../util/enum'
import { ruleDict, thisWeekNumber } from './../util/falRuleWrapper'
import { byteString, assert } from '../util/utilityFunctions'
// Algorithm uses dynamic programming to generate all possible
// combinations of swaps and specials to find the 
// best path to get the highest amount of points. 


// Parameters: 
  // dict: myteam[showId] = TEAMSTATUS
  // dict: predictions[GRAPHTYPE/total] = arr[showId][week] = points
const findBestTeam = (myTeam, predictions, falParameters, calculatedPoints) => {
  const teamToIdArr = []
  let teamStr = ''
  Object.keys(myTeam).forEach((key, idx) => {
    teamToIdArr.push(parseInt(key))
    teamStr += myTeam[key] == TEAMSTATUS.active ? '1' : '0'
  })

  // Create aceUsed number from falParameters
  const initAced = falParameters.aced
  const teamSize = ruleDict['teamSize']
  const initAceUsed = Object.keys(myTeam
    ).reduce((prev, curr, idx) => {
      if(!initAced[curr]){ return prev }
      return (1<<(teamSize-idx-1)) + prev
    }, 0)

  // [week, team(11111000), swapsUsed, specialUsed, aceUsed(00000000)]
  let state = [
    thisWeekNumber, 
    parseInt(teamStr, 2), 
    falParameters.swapsUsed, 
    falParameters.specialUsed, 
    initAceUsed
  ]
  let dp = {}     // Key: state, Value: pts of curr week onward
  let pathDp = {} // The path of the dp

  const dfs = (thisState) => {
    const [week, team, swapsUsed, specialUsed, aceUsed] = thisState
    const thisStateKey = JSON.stringify(thisState)

    // Base case: week is greater than total weeks
    if(week > ruleDict['totalWeeks']) {
      pathDp[thisStateKey] = thisStateKey

      // If special is unused by week 13, force use at any point
      return specialUsed === 0 ? ruleDict['bonusPoints'] : 0
    } 

    // If state inside dictionary, use it
    if(thisStateKey in dp) {
      return dp[thisStateKey]
    }

    // No swaps and special route
    const [pts, tempAceUsed] = calcScore(week, team, aceUsed)
    const tempNextState = [week+1, team, swapsUsed, specialUsed, tempAceUsed]
    dp[thisStateKey] = pts + dfs(tempNextState)

    // Store the path (needs initial comparison)
    storeBestPath(thisStateKey, tempNextState, dp[thisStateKey])

    // Generate all possible swap combinations
    const combos = generateSwapCombinations(team)

    // Check swap
    const useSwap = swapsUsed < ruleDict['totalSwaps']

    // Check special (extra swap)
    const useSpecial = week >= ruleDict['specialStartWeek'] 
                        && specialUsed < ruleDict['totalSpecials']
    assert(week <= 13, `week is${week}`)             
    // If swap or special is available, use it.
    if(useSwap || useSpecial) {
      combos.forEach(combo => {
        const [points, updatedAceUsed] = calcScore(week, combo, aceUsed)
        const nextState = useSwap 
          ? [week+1, combo, swapsUsed+1, specialUsed, updatedAceUsed]
          : [week+1, combo, swapsUsed, 1, updatedAceUsed]

        // Recurse: current state pts + future state pts
        let newPoints = points+dfs(nextState)
        newPoints += (!useSwap && useSpecial) ? ruleDict['extraSwapCost'] : 0

        dp[thisStateKey] = Math.max(newPoints, dp[thisStateKey])
        storeBestPath(thisStateKey, nextState, newPoints)
      })
    }
    return dp[thisStateKey]
  }

  const storeBestPath = (thisStateKey, nextState, newPoints) => {
    const nextStateKey = JSON.stringify(nextState)
    const intermediateState = [...nextState]
    intermediateState[0] -= 1
    const intermediateKey = JSON.stringify(intermediateState)
    if(dp[thisStateKey] == newPoints) {
      pathDp[thisStateKey] = `${intermediateKey}\n${pathDp[nextStateKey]}`
    }
  }

  const calcScore = (week, team, aceUsed) => {
    let sum = 0
    const teamArr = byteString(team).split('') // convert team to array

    // Get active team
    teamArr.forEach((elem, idx) => {
      if(elem === '1') {
        const showId = parseInt(Object.keys(myTeam)[idx])
        sum += calculatedPoints[showId][week]
      }
    })

    // Calc ace
    const [acedPoints, updatedAceUsed] = calcAce(teamArr, aceUsed, week)
    sum += acedPoints
    return [sum, updatedAceUsed]
  }

  const calcAce = (teamArr, aceUsed, week) => {
    const maxAceValue = (1 << ruleDict['teamSize']) - 1
    assert(maxAceValue === 255, `max ace not 255 ${maxAceValue}`)
    // Try ace
    if(aceUsed !== maxAceValue) {
      // Aced show must have greatest points and be under 85k* (May not be 85k for diff seasons)
      const maxActiveShow = getMaxActiveShowInDecimal(teamArr, week) 

      // Check if 'maxActiveShow' only has one high bit
      assert(maxActiveShow === (maxActiveShow & -maxActiveShow), 
            `maxActiveShow has multiple high bits ${maxActiveShow}`)

      if(maxActiveShow !== 0) {
        const available = (maxActiveShow & (aceUsed ^ maxAceValue))
        // Check if 'available' only has one high bit
        assert(available === (available & -available), 
              `available ace has multiple high bits ${available}`)

        if(available) {
          const updatedAceUsed = aceUsed | available
          return [ruleDict['acePoints'], updatedAceUsed]
        }
      }
    } 
    return [0, aceUsed]
  }

  const getMaxActiveShowInDecimal = (teamArr, week) => {
    let maxPoints = 0
    let maxActiveShowIdx = 0
    Object.keys(myTeam).forEach((showId, idx) => {
      const watchers = predictions[showId][GRAPHTYPES.watchers][week]
      const points = calculatedPoints[showId][week]

      // Check if show is active and less than max members (85000)
      if(parseInt(teamArr[idx]) && watchers < ruleDict.maxMembers
          && maxPoints < points) {
        maxPoints = points
        maxActiveShowIdx = 1 << (ruleDict['teamSize']-idx-1)
      }
    })
    return maxActiveShowIdx
  }

  const generateSwapCombinations = (team) => {
    const teamArr = byteString(team).split('') // convert team to array
    assert(teamArr.length === 8,
          `team is not 8 bits ${teamArr}`)

    // Find index of all 0s and 1s
    const benchIndexes = []
    const activeIndexes = []
    teamArr.forEach((elem, idx) => {
      if(elem === '1'){
        activeIndexes.push(idx)
      } else {
        benchIndexes.push(idx)
      }
    })

    // For each, perform a swap and push into 'swaps'
    const combos = []
    activeIndexes.forEach((itemA) => {
      benchIndexes.forEach((itemB) => {
        const tempTeam = [...teamArr]
        tempTeam[itemA] = '0' // active becomes bench
        tempTeam[itemB] = '1' // bench becomes active
        combos.push(parseInt(tempTeam.join(''),2))
      })
    })
    return combos
  }

  const run = () => {
    // Call dfs to start algorithm
    const maxPoints = dfs(state)
    const bestPathArr = pathDp[JSON.stringify(state)].split('\n')
    const points = []

    bestPathArr.forEach((elem, idx) => {
      if(idx === 0){
        return
      }
      const entry = JSON.parse(elem)
      const entry2 = JSON.parse(bestPathArr[idx-1])
      points.push([entry[0]-1, byteString(entry[1]), 
                  calcScore(entry[0]-1, entry[1], entry2[4])[0]])
    })

    assert(maxPoints !== undefined || isNaN(maxPoints), "maxPoints undefined")
    assert(bestPathArr !== undefined, "bestPathArr undefined")
    assert(points !== undefined, "points undefined")

    return [maxPoints, bestPathArr, points]
  }
  return run()
}
export default findBestTeam