import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { assert, dateDiffInDays, formatToDate } from './../util/utilityFunctions'
import { GRAPHTYPES, TEAMSTATUS, STRATEGYSTATUS, TYPECOLORS } from '../util/enum'
import findBestTeam from './../calculations/findBestTeam'
import { ruleDict,
          allFalAwardDates, 
          thisWeekNumber,
          criteria,
          getWeekNumber
       } from './../util/falRuleWrapper'
import { calcPoints } from './../calculations/calcPoints'
import './../css/strategyTable.css'


const Strategy = ({extrapolatedData,
                   myTeam, contents,
                   falParameters}) => 
{
  console.log(`Welcome to Strategy.js`, falParameters)

  // Check if shows that are not on the team are in the falparameters
  const tempFalAced = Object.keys(falParameters.aced).filter((key) => {
    return !myTeam.hasOwnProperty(key)
  })
  assert(Object.keys(tempFalAced).length === 0, 
    `shows that are not on the team are in falParameter`)

  let latestContentWeekNum = 1
  let latestContentDate = new Date(0) // Oldest date 
  let dates = {}
  const contentValues = Object.values(contents)
  if(contentValues.length > 0){
    // For each entry, get the latest date
    contentValues.forEach((entry) => {
      if(entry.dates.length > 0){
        latestContentDate = new Date(
          Math.max.apply(null, [entry.dates[entry.dates.length-1], latestContentDate])
        )
      }
    })
  } else {
    // If show hasn't started, then make latest date today
    latestContentDate = new Date()
  }

  // Make sure week minimum is 1
  latestContentWeekNum = Math.max(getWeekNumber(latestContentDate), 1)
  const formatStrategy = (bestStrat) => {
    const strategy = {}

    // Put in previous week's entry
    const initAced = parseInt(Object.values(falParameters.aced).reduce((prev, curr) => {
      return prev + (curr ? '1' : '0')
    }, ''), 2)

    // Put in this weeks + future entries
    bestStrat.forEach((strat, idx) => {
      const stratArr = JSON.parse(strat)
      if(stratArr[0] <= ruleDict.totalWeeks) {
        assert(stratArr.length === 5, `strat array wrong length ${stratArr}`)
        const prev = idx-1 >= 0 
          ? JSON.parse(bestStrat[idx-1])[4] 
          : initAced
        const a = stratArr[4] ^ prev
        const aced = "00000000".substr(a.toString(2).length) + a.toString(2)
     
        assert(!(a & (a-1)), `multiple aces in one week found 
          ${initAced} ${(a-1).toString(2)} ${aced} `)

        const b = stratArr[1].toString(2)
        const binaryTeam = "00000000".substr(b.length) + b
        const tempTeam = {}
        Object.keys(myTeam).forEach((showId, idx) => {
          tempTeam[showId] = binaryTeam[idx] === '1' ? 'active' : 'bench'
        })

        const tempSwap = idx-1 >= 0 
                        ? JSON.parse(bestStrat[idx-1])[2] !== stratArr[2] 
                        : JSON.parse(bestStrat[0])[2] !== falParameters.swapsUsed

        const tempAce = Object.keys(myTeam).find((showId, idx) => 
          aced[idx] === '1' 
        )

        const special = stratArr[3] === 1 ? 'swap' : ''
      
        strategy[stratArr[0]] = 
          {'team':tempTeam, 'swapIn':tempSwap, 'ace':tempAce ?? '', 'special':special}
      }
    })
    return strategy
  }

  // Get extrapolatedParameters from Extrapolation.js
  const generatePredictedWeeklyData = (latestContentWeekNum) => {
    assert(Object.values(extrapolatedData)[0].dates.length > 0,
      'extrapolatedData data is missing')

    // Data: [showId][type][pts]
    const predictedData = {}

    Object.keys(extrapolatedData).forEach(showId => {
      predictedData[showId] = {}
      const entry = extrapolatedData[showId]
      
      Object.keys(GRAPHTYPES).forEach(type => {
        predictedData[showId][type] = {}

        for(let week = latestContentWeekNum; week <= ruleDict.totalWeeks; week++){
          const awardDate = allFalAwardDates[week-1]

          // Since awarddates are 22:00 GMT, we only check up to the date
          const foundIdx = entry.dates.findIndex(d => 
            d.getFullYear() === awardDate.getFullYear() &&
            d.getMonth() === awardDate.getMonth() &&
            d.getDate() === awardDate.getDate()
          )
          
          const msg = `awardDate ${entry.dates} entry not found in awardDate, Strategy.js 49`
          assert(foundIdx !== -1, msg)

          predictedData[showId][type][week] = entry[type][foundIdx]
        }
      })     
    })
    return predictedData
  }

  const getWeeklyContents = (latestContentWeekNum) => {
    // const dates = Object.values(contents)[0].dates
    // console.log(dates, 'datetes', contents)
    // const latestContentDate = dates[dates.length-1].getTime()

    const tempWeeklyContents = {} // [showId][type][week]
    Object.keys(myTeam).forEach(showId => {
      tempWeeklyContents[showId] = {}

      Object.keys(GRAPHTYPES).forEach(type => {
        tempWeeklyContents[showId][type] = {}
        for(let week = 1; week < latestContentWeekNum; week++) {
          const falAwardDate = allFalAwardDates[week-1] // Array 

          // Unfortunately, falAwardDates are at 22:00 GMT while content stats is at 00:00
          const tempDate = new Date(falAwardDate.getFullYear(), 
                                    falAwardDate.getMonth(), 
                                    falAwardDate.getDate())
          
          if(tempDate in contents[showId].stats) {
            tempWeeklyContents[showId][type][week] = 
              contents[showId].stats[tempDate][type]
          } else if(falAwardDate < latestContentDate && falAwardDate > contents[showId].dates[0]){
            const showDates = contents[showId].dates

            // Interpolate missing data
            const dateIdx = showDates.findIndex((date, idx) => 
              idx !== 0 && falAwardDate < date && falAwardDate > showDates[idx-1]
            )
            // console.log(dateIdx, "dateIdx", falAwardDate)
            const prevDate = new Date(showDates[dateIdx-1])
            const nextDate = new Date(showDates[dateIdx])
            
            // console.log('falAwardDate', falAwardDate, 'prevDate', prevDate, 'nextDate', nextDate)
            const diff = dateDiffInDays(prevDate, nextDate)
            const y1 = contents[showId].stats[prevDate][type]
            const y2 = contents[showId].stats[nextDate][type]
            const interpolated = ((y2-y1)/diff) * dateDiffInDays(prevDate, falAwardDate) + y1

            // Round to 2 decimal if score
            tempWeeklyContents[showId][type][week] = 
              type !== GRAPHTYPES.score ? Math.round(interpolated) : 
                Math.round((interpolated + Number.EPSILON) * 100) / 100
          } else {
            // Mostlikely, the falAwardDate comes before the show started
            tempWeeklyContents[showId][type][week] = 0
          }
        }
      })
    })
    // console.log(tempWeeklyContents, 'tempWeeklyContents')
    return tempWeeklyContents
  }

  const combineData = (weeklyContents, weeklyData, latestContentWeekNum) => {
    const combinedData = {}
    Object.keys(myTeam).forEach(showId => {
      combinedData[showId] = {}
      Object.keys(GRAPHTYPES).forEach(type => {
        combinedData[showId][type] = {}
        for(let week = 1; week <= ruleDict.totalWeeks; week++){
          if(week < latestContentWeekNum){
            combinedData[showId][type][week] = weeklyContents[showId][type][week]
          } else {
            combinedData[showId][type][week] = weeklyData[showId][type][week]
          }
        }
      })
    })
    return combinedData
  }
  
  //[showId][type][week]
  const weeklyData = generatePredictedWeeklyData(latestContentWeekNum)

  //[showId][type][week]
  const weeklyContents = getWeeklyContents(latestContentWeekNum)

  //[showId][type][week]
  const combinedData = combineData(weeklyContents, weeklyData, latestContentWeekNum)

  // Precalculate points for each show for each week and store it
  // weeklyShowPoints[showId][week]
  const weeklyShowPoints = 
    calcPoints(Object.keys(myTeam), combinedData)

  // Call the strategy calculation function
  const [maxPoints, bestStrategy, points] = 
    findBestTeam(myTeam, combinedData, falParameters, weeklyShowPoints)

  const formattedStrategy = formatStrategy(bestStrategy)
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell><strong>Week</strong></TableCell>
          {Object.keys(contents).length !== 0 &&
            Object.keys(contents).map(showId => (
              <TableCell align="right" width="15%" key={'head'+showId}>
                <strong>{contents[showId].title}</strong>
              </TableCell>
            ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(formattedStrategy).length !== 0 &&
          Object.keys(formattedStrategy).map((week, idx) => (
            <TableRow key={week}>
              <TableCell>
                {week} &nbsp;
                ({allFalAwardDates[parseInt(week)-1].getMonth()+1}/
                {allFalAwardDates[parseInt(week)-1].getDate()}) &nbsp;
                {criteria[week].map(type => {
                  return <div style={{color: TYPECOLORS[type],
                                      display: 'inline-block'}}
                              key={`PointFactor ${week} ${type}`}
                          >
                          {type[0].toUpperCase()}
                        </div>
                })}
              </TableCell>
              {
                Object.keys(formattedStrategy[week].team).map((showId, idx) => {
                
                  const team = formattedStrategy[week].team
                  let stat = team[showId] === TEAMSTATUS.active
                              ? STRATEGYSTATUS.active : STRATEGYSTATUS.bench
                  let cssColor = 'table'

                  const prevTeam = week-1 in formattedStrategy 
                                  ? formattedStrategy[week-1].team : myTeam

                  if(formattedStrategy[week].ace === showId){
                    cssColor += '-Ace' 
                    stat = (prevTeam !== -1 && team[showId] !== prevTeam[showId])
                            ? STRATEGYSTATUS.activeAndAce : STRATEGYSTATUS.ace
                  } else if(prevTeam !== -1 && team[showId] !== prevTeam[showId]){
                    cssColor += team[showId] === TEAMSTATUS.active
                            ? '-Active' : '-Bench'
                  } 

                  // If watchers > 75k, grey out (may not be 75k for some seasons)
                  if(combinedData[showId][GRAPHTYPES.watchers][week] > ruleDict.maxMembers){
                    cssColor += '-OverMaxWatchers'
                  }

                  assert(!(cssColor.includes('Ace') && cssColor.includes('Max')), 
                        'cssColor ace + overMaxWatchers')

                  return (<TableCell align="right" 
                            key={`${showId}${week}`} 
                            className={cssColor}>
                            <div>
                              {stat}<br/>{weeklyShowPoints[showId][week]}
                            </div>
                          </TableCell>)
                })
              }
            </TableRow>
          ))
        }
      </TableBody>
      </Table>
    </TableContainer>
  )
} 
export default Strategy