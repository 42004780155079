// Utility functions for findBestTeam algorithm

export const assert = (condition, message) => {
  if(!condition){
    throw message
  }
}


// Convert decimal to binary for FAL team of size 8
export const byteString = (n) => {
  if (n < 0 || n > 255 || n % 1 !== 0) {
      throw new Error(n + " does not fit in a byte")
  }
  return ("000000000" + n.toString(2)).substr(-8)
}


export const dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}


export const convertDatesToIndicies = (dateArr) => {
  const dates = !(dateArr[0] instanceof Date) ? formatArrToDates(dateArr) : dateArr
  const dateIndicies = []

  dates.forEach((d, idx) => {
    assert(Object.prototype.toString.call(d) === '[object Date]',
      `${d} is not a date`)
    if(idx === 0) {
      dateIndicies.push(0)
    } else {
      const shift = dateDiffInDays(dates[idx-1], d)
      dateIndicies[idx] = dateIndicies[idx-1] + shift
    }
  })
  return dateIndicies
}


export const formatDateToStr = (date) => {
  return (1+date.getMonth()).toString()
  +"/"+(date.getDate().toString())
  +"/"+(date.getFullYear()).toString()
}


// Format the str dates(YYYYMMDD) to date objects
export const formatArrToDates = (dateArr) => {
  return dateArr.map(d => {
    assert(d.length === 8, "error in formatArrToDates. date not 8 char long")
    return new Date(
      Date.parse(d.substring(0,4)+"/"+
                  d.substring(4,6)+"/"+
                  d.substring(6,8))
    )
  })
}


export const formatToDate = (d) => {
  assert(d.length === 8, "error in formatToDate. date not 8 char long")
  return new Date(
    Date.parse(d.substring(0,4)+"/"+
                d.substring(4,6)+"/"+
                d.substring(6,8))
  )
}


export const getDaysArray = (start, end) => {
  const arr = []
  for(let dt = new Date(start); 
      dt <= new Date(end); 
      dt.setDate(dt.getDate()+1)
  ){
      arr.push(new Date(dt))
  }
  return arr
}


export const compareDictionaries = (d1, d2) => {
  for(const key of Object.keys(d1)) {
    // console.log(key, d1, d2, d1[key], d2[key], d1[key] == d2[key])
    if(!(key in d2)){
      return false
    }
    if(d1[key] != d2[key]){
      return false
    }
  }
  for(const key of Object.keys(d2)) {
    if(!(key in d1)){
      return false
    }
    if(d1[key] != d2[key]){
      return false
    }
  }
  return true
}
