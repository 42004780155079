import { useEffect, useState } from 'react'
import {
  Link,
  useNavigate,
  useLocation
} from "react-router-dom"

import {
  AppBar,
  Button,
  Container, 
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography
} from '@mui/material'
import servers from './../data/servers'
import { ruleDict } from './../util/falRuleWrapper'
import { fetchFalEntries } from '../util/serverInterface'
import tableSort from './../util/tableSort'
import Shows from './Shows'
import { isTargetNameAssociation } from '@aws-amplify/datastore'
import { TEAMSTATUS } from './../util/enum'
import { addDataIntoCache, 
          getDataFromCache, 
          deleteEntireCache,
          deleteFromCache } from '../util/cacheInterface'
import { isDisabled } from '@testing-library/user-event/dist/utils'
import { assert } from '../util/utilityFunctions'


const Team = () => {
  const [myTeam, setMyTeam] = useState({})
  const [disableBenches, setDisableBenches] = useState(false)
  const [disableActives, setDisableActives] = useState(false)
  const [enableSubmitButton, setEnableSubmitButton] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [orderedData, setOrderedData] = useState([])
  const [orderedTeamData, setOrderedTeamData] = useState([])
  const [orderDirection, setOrderDirection] = useState('desc')
  const [selectedValues, setSelectedValues] = useState({})
  const [isSwapping, setIsSwapping] = useState(false)
  const [swappingShow, setSwappingShow] = useState(-1)
  const [disableSwaps, setDisableSwaps] = useState([])
  const [numCurrActive, setNumCurrActive] = useState(0)
  const [numCurrBench, setNumCurrBench] = useState(0)

  const navigate = useNavigate()
  const location = useLocation()
  const maxActiveNum = ruleDict.numOfActive
  const maxBenchNum = ruleDict.numOfBench
  const maxTeamNum = ruleDict.teamSize
  const seasonNum = ruleDict.seasonNum

  const url = window.location.href

  // Get Database
  useEffect(() => {
    console.log("Welcome to team page")

    const fetchData = async (season) => {
      // If content not sent through state, search.
      const sortedData = location.state ?
        location.state : await fetchFalEntries(season)
      
      setOrderedData(sortedData)

      // Check cache for team
      let tempMyTeam = myTeam
      const cacheData = await getDataFromCache('myFalTeam', url)
      if(cacheData !== undefined) {
        tempMyTeam = cacheData
        setMyTeam(cacheData)
        setIsSubmitted(true)
        const t = sortedData.filter(e => tempMyTeam[String(e.id)] !== undefined)
        setOrderedTeamData(t)
      } 

      const dict = {} // Contains all shows
      if(Object.keys(tempMyTeam).length === 0){
        sortedData.forEach(show => {
          dict[show.id] = "none"
        })
      } else {
        sortedData.forEach(show => {
          const stat = tempMyTeam[String(show.id)]
          dict[show.id] = stat !== undefined ? stat : 'none'
        })
      }
      setSelectedValues(dict)
    }

    fetchData(seasonNum) 
  },[])

  const updateTeamStatus = (tempMyTeam) => {
    // Disable the shows that were not chosen
    const activeCount = Object.values(tempMyTeam).reduce((sum, showStatus) => {
      return sum + (showStatus === TEAMSTATUS.active) 
    }, 0)
    const benchCount = Object.values(tempMyTeam).reduce((sum, showStatus) => {
      return sum + (showStatus === TEAMSTATUS.bench) 
    }, 0)
    const hasMaxActives = activeCount >= maxActiveNum
    const hasMaxBenches = benchCount >= maxBenchNum
    setDisableActives(hasMaxActives)
    setDisableBenches(hasMaxBenches)
  }

  const handleSortRequest = (event) => {
    setOrderedData(tableSort(orderedData, orderDirection, event))
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')
  }

  const handleChangeTeam = (event) => {
    setIsSubmitted(false)
    updateTeamStatus(myTeam)
    const tempSubmit = Object.keys(myTeam).length === maxTeamNum
    setEnableSubmitButton(tempSubmit)
    
    setNumCurrActive(Object.values(myTeam).filter((value) => 
                      value == TEAMSTATUS.active
                    ).length)
    setNumCurrBench(Object.values(myTeam).filter((value) => 
                    value == TEAMSTATUS.bench
                  ).length)
  }

  const handleRadio = (event, id) => {
    const value = event.target.value
    let tempMyTeam = myTeam

    if(value !== "none"){
      // Updating or adding doesn't matter
      tempMyTeam[id] = value
    } else {
      const foundKey = Object.keys(tempMyTeam).find((key) => key===String(id))
      if(foundKey !== undefined){
        delete tempMyTeam[foundKey]
      } else {
        console.error('tried removing key but not found', foundKey, id)
      }
    }

    if(Object.keys(tempMyTeam).length > maxTeamNum){
      console.error('checked box exceeds max team number', tempMyTeam)
    }

    console.log(tempMyTeam, Object.keys(tempMyTeam).length)
    updateTeamStatus(tempMyTeam) // Change variables that disable radio buttons
    setSelectedValues({...selectedValues, [id]: value})
    const tempSubmit = Object.keys(tempMyTeam).length === maxTeamNum
    setEnableSubmitButton(tempSubmit)
    setMyTeam(tempMyTeam)
    setNumCurrActive(Object.values(tempMyTeam).filter((value) => 
                      value == TEAMSTATUS.active
                    ).length)
    setNumCurrBench(Object.values(tempMyTeam).filter((value) => 
                    value == TEAMSTATUS.bench
                  ).length)

  }

  const handleDisableRadioButtons = (id, isBench) => {
    const disableType = !isBench ? disableActives : disableBenches
    const teamType = !isBench ? TEAMSTATUS.active : TEAMSTATUS.bench
    return disableType
      ? myTeam[String(id)] === undefined || myTeam[String(id)] !== teamType
      : false
  }

  const handleSubmitTeam = (event) => {
    setOrderedTeamData(
      orderedData.filter(e => myTeam[String(e.id)] !== undefined)
    )
      
    //! Get rid of nonteam entries from the cache
    setIsSubmitted(true)
    addDataIntoCache('myFalTeam', url, myTeam)
    deleteFromCache('falParameters') //Erase falParameters
  }

  const handleIsSwapping = (event) => {
    setIsSwapping(!isSwapping)
  }

  // Handler for editing team status
  const handleSwap = (event, id) => {
    const value = event.target.value
    if(swappingShow === -1){
      setSwappingShow(id)
      Object.keys(myTeam).forEach(showId => {
        disableSwaps[showId] = myTeam[showId] === myTeam[id]
      })
    } else {
      const tempMyTeam = myTeam
      assert(tempMyTeam[swappingShow] !== tempMyTeam[id], 
        'swapping shows are the same stat')
      
      const tempStat = tempMyTeam[id]
      tempMyTeam[id] = tempMyTeam[swappingShow]
      tempMyTeam[swappingShow] = tempStat

      Object.keys(myTeam).forEach(showId => {
        disableSwaps[showId] = false
      })

      const activeCounter = Object.values(tempMyTeam).reduce((sum, showStatus) => {
        return sum + (showStatus === TEAMSTATUS.active) 
      }, 0)
      const benchCounter = Object.values(tempMyTeam).reduce((sum, showStatus) => {
        return sum + (showStatus === TEAMSTATUS.bench) 
      }, 0)
     
      assert(ruleDict.numOfActive === activeCounter, 
        `active number is wrong ${activeCounter}`)
      assert(ruleDict.numOfBench === benchCounter, 
        `active number is wrong ${benchCounter}`) 
      if(ruleDict.numOfActive === activeCounter || 
        ruleDict.numOfBench === benchCounter){
        setMyTeam(tempMyTeam)
        addDataIntoCache('myFalTeam', url, tempMyTeam)
      }
      setSwappingShow(-1)
      setIsSwapping(false)
    }
  }

  const handleEraseTeam = () => {
    const confirmed = window.confirm('Erase team data and all prediction settings?')
    if(confirmed){
      deleteEntireCache()
      alert('Complete Cache Cleared')
      window.location.reload(false)
    }
  }

  const tableTeamEntry = orderedData.length !== 0 ? (
    <Table id="table2" sx={{ minWidth: 650 }} size="small"> 
      <TableHead>
        <TableRow>
          <TableCell >
            <TableSortLabel active={true} direction={orderDirection}>
              Team
            </TableSortLabel>
          </TableCell>
          <TableCell onClick={() => handleSortRequest('title')}>
            <TableSortLabel active={true} direction={orderDirection}>
              Title
            </TableSortLabel>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            N/A&nbsp;&nbsp;Active&nbsp;&nbsp;Bench
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderedData.map((show, idx) => (
          <TableRow key={show.id}>
            <TableCell style={{minWidth: '200px'}}>
              {Object.keys(selectedValues).length !== 0
                ? <RadioGroup row value={selectedValues[show.id]} 
                  onChange={(e) => handleRadio(e, show.id)}>
                  <FormControlLabel value="none" control={<Radio />} />
                  <FormControlLabel value="active" control={<Radio />} 
                    disabled={handleDisableRadioButtons(show.id, false)}/>
                  <FormControlLabel value="bench" control={<Radio />} 
                    disabled={handleDisableRadioButtons(show.id, true)}/>
                </RadioGroup>
                : <div>Loading...</div>
              }
            </TableCell>
            <TableCell>
              <Link to={`/shows/${show.id}`}>
                {show.title.replaceAll('_',' ')}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (<div>Loading...</div>)

  const tableTeam = orderedData.length !== 0 ? (
    <Table id="table3">
      <TableHead>
        <TableRow>
          <TableCell onClick={() => handleSortRequest('title')}>
            <TableSortLabel active={true} direction={orderDirection}>
              Title
            </TableSortLabel>
          </TableCell>
          <TableCell>
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderedTeamData.length !== 0 
        ? orderedTeamData.map(show => (
          <TableRow key={show.id}>
            <TableCell>
              <Link to={`/shows/${show.id}`}>
                {show.title.replaceAll('_',' ')}
              </Link>
            </TableCell>
            <TableCell>
            {Object.keys(myTeam).length !== 0
              ? (myTeam[show.id] == TEAMSTATUS.active
                ? <strong style={{color: "green"}}>ACTIVE</strong>
                : <div style={{color: "red"}}>BENCH</div>)
              : <div>Loading...</div>
            }
            </TableCell>
            <TableCell>
              {isSwapping 
                ? <Button onClick={(e) => 
                    handleSwap(e, show.id)}
                    disabled={disableSwaps[show.id]}>
                    Swap 
                  </Button> 
                : <div></div>
              }
            </TableCell>
          </TableRow>
          ))
        : (<TableRow>
            <TableCell>
              Loading team from cache...
            </TableCell>
          </TableRow>)
        }
      </TableBody>
    </Table>
  ) : (<div>Loading...</div>)
  
  const buttonStyle = {
    float: "left",
    backgroundColor: "#1e8fff",
    color: "white",
    margin: "8px",
    }

  const submitButtonEnabledStyle = {
    float: 'right', 
    fontSize: '20px',
    backgroundColor:'#86c280',
    fontWeight: 'bold',
    color:'primary'
  }

  const submitButtonDisabledStyle = {
    float: 'right', 
    fontSize: '20px',
    backgroundColor:'grey',
    fontWeight: 'bold',
    color:'primary'
  }

  return (
    <Container style={{fontFamily: ".roboto-medium",}}>
      {orderedData.length !== 0 && <div>
        {isSubmitted
          ? <div>
              <Button style={buttonStyle}
                onClick={handleIsSwapping}>
                Swap
              </Button>
              <Button style={buttonStyle}
                onClick={handleChangeTeam}>
                Change Team
              </Button>
              <Button style={buttonStyle}
                onClick={() => {
                  navigate('/predictions', {state: orderedTeamData})
                }}>
                Predict Points
              </Button>
              <Button style={{...buttonStyle, backgroundColor: "#e3431b" }}
                onClick={handleEraseTeam}>
                Erase Team
              </Button>
              {tableTeam}
            </div>
          : <div>
              <AppBar position="sticky" elevation={0} 
                      style={{top: "60px", backgroundColor: "#d6d6d6"}} >
                <Toolbar>
                  <Button 
                      style={enableSubmitButton 
                                  ? submitButtonEnabledStyle
                                  : submitButtonDisabledStyle}
                      variant="contained"
                      onClick={handleSubmitTeam} 
                      disabled={!enableSubmitButton}
                      elevation={0}>
                    Submit
                  </Button>
                  <div style={{margin:"15px", 
                              color: numCurrActive === maxActiveNum
                                ? "black"
                                : "red"
                              }}>
                    ACTIVE: &nbsp; {numCurrActive} &nbsp; / {maxActiveNum} &nbsp;
                  </div>
                  <div style={{margin:"15px", 
                              color: numCurrBench === maxBenchNum
                                ? "black"
                                : "red"
                              }}>
                    BENCH: &nbsp; {numCurrBench} &nbsp; / {maxBenchNum}
                  </div>
                </Toolbar>
              </AppBar>
              {tableTeamEntry}
            </div>
        }
      </div> }
    </Container>
  )
}
export default Team