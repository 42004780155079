import { useEffect, useState } from 'react'
import {
  AppBar,
  Button,
  Container, 
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  setRef
} from '@mui/material'


const About = () => {

  const [showControls, setShowControls] = useState(false)
  const [showPrediction, setShowPrediction] = useState(false)

  const predictionText = (
    <div>
      <p>Each show has 5 plots. Score, watchers, favorites, dropped, posts.
        The colored line on each graph represents the data points taken from MAL's website. 
        The grey line represents your prediction for the future. The goal is to match the
        grey line to the colored line as close as possible. 
      </p>
      <p>
        Note that the calculations will only take the predicted data points AFTER the real data points stop.
        So even if the predicted data doesn't match the real data before today, it will not matter.
      </p>
      <p>
        Here are some tips and tricks: 
      </p>
      <ol>
        <li>
          The data points closer to today should be prioritized over data points in the past
        </li>
        <li>
          The graphs for Dropped, Favorites, and Posts usually don't need to be adjusted. 
          The predicted line should be quite straight.  
        </li>
        <li>
          To adjust the graph, start by matching the overall curve of the graph. 
          Is it going up or down? Check or uncheck "decreasing" as necessary.
        </li>
        <li>
          Next, adjust "long-term growth". This adjusts how curved the prediction line should be.
          Remember to prioritize recent data points more than past data points
        </li>
        <li>
          Adjust "short-term growth". This tilts the whole line either up or down.
        </li>
        <li>
          By now, your line should match the overall "curve" of the real data points. 
          If you need to move your entire prediction line up or down, adjust the y-shift
        </li>
        <li>
          You would usually not need to adjust the "x-shift". 
          However, if the plot has multiple curves (typically used in the score of a show), 
          you may need to shift the entire graph left or right to fit it. 
          Cases include: the show had delayed airing.
          The show had a massive jump (up or down) and the past data points no longer 
          represent how the overall show is doing. 
        </li>
        <li>
          "final week x shift" and "final week y shift" are used to create a sudden change in the 
          prediction line which would represent the boost that happens in the 13 week of a show when it 
          finishes airing. I personally ignore this since I can't predict how much a show would boost up
          by when it finishes airing. 
        </li>
      </ol>
    </div>
  )

  const controlsText = (
    <div>
      <h3>Main controls</h3>
      <h5>Decreasing</h5>
        <p>Flips the line upside down.</p>
      <h5>Long-term growth:</h5>
        <p>A value of 0 is a horizontally flat line. A value of 1 is a slanted line. 
          Values in between represents how curved the line will be.</p>
      <h5>Initial growth:</h5>
        <p>Changes the overall tilt of the graph. </p>
      <h5>Y Shift:</h5>
        <p>Moves the graph up and down</p>
      <h5>X Shift:</h5>
        <p>Moves the graph left and right</p>

      <h5>Mathematical Explanation</h5>
        <p>yShift + decreasing * initial_growth*(x - xShift) ^ long-term_growth</p>
        <p>The equation is based off of a fractional polynomial equation. 
          Unlike a typical polynomial, the degree is less than 1 but greater than 0</p>
        <p>y_ + i * A * (x - x_) ^ B</p>
        <p>, where "B" is between 0 and 1 and "i" is either -1 or 1</p>

      <h3>Extra controls</h3>
      <h5>Final Week X Shift:</h5>
        <p>When a show ends, usually most of the graphs spikes up in value. 
          This value represents what day the show will starts spiking up.
          Changes the graph's position after a certain date in the horizontal direction.
          You may not see changes in the graph until you change the 
          'Final Week Y Shift' simultaneously.</p>
      <h5>Final Week Y Shift:</h5>
        <p>This value represents how much the graph will spike up when the show ends airing.</p>
      
      <h3>Buttons</h3>
      <h5>Reset:</h5> 
        <p>Resets the graph to the default value</p>
      <h5>Show Equation:</h5> 
        <p>Shows the equation of the graph.</p>
    </div>
  )

  return (
    <div>
    <h1>Use the website to view MAL show predictions and plan your aces and swaps!</h1>
    <p>Website may not work on mobile</p>

    <p>In order to save your predictions, just click on a different show or click "go next"</p>
    <div style={{ display: 'flex', color: "black"}} >
      <Grid container spacing={2} alignItems="center">
      <Grid item>
        {showPrediction 
          ? <div>
              <Button style={{float: 'left', 
                      fontSize: '12px',
                      color: 'white',
                      backgroundColor: 'DodgerBlue'}}
                      onClick={() => setShowPrediction(false)}>
                Hide Hint to using the Prediction Graph
              </Button>
              <br/>
              {predictionText}
            </div>
          : <Button style={{float: 'left', 
                    fontSize: '12px',
                    color: 'white',
                    backgroundColor: 'DodgerBlue'}}
                    onClick={() => setShowPrediction(true)}>
            Show Hint to using the Prediction Graph
          </Button>
        }
      </Grid>
      <Grid item>
        {showControls 
          ? <div>
              <Button style={{float: 'left', 
                      fontSize: '12px',
                      color: 'white',
                      backgroundColor: 'DodgerBlue'}}
                      onClick={() => setShowControls(false)}>
                Hide Controls
              </Button>
              <br/>
              {controlsText}
            </div>
          : <Button style={{float: 'left', 
                    fontSize: '12px',
                    color: 'white',
                    backgroundColor: 'DodgerBlue'}}
                    onClick={() => setShowControls(true)}>
            Show Controls
          </Button>
        }
      </Grid>
      </Grid>
    </div>
    </div>
  )
}
export default About