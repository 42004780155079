import { BayesianOptimizer } from "bayesian-optimizer";
import { fracEquation } from "./regressionEquations"
import { GRAPHTYPES } from '../util/enum'

// "data" is a dictionary containing values per date index(0,1...n)
// "currentParams" is a dictionary containing regression values
export  const bayesianOptimization = async (graphType, data, regInfo, searchSpaceInputs) => 
{

  // Define your objective function
  const objectiveFunction = async (params) => {
    // yShift will be difference between the last predicted
    // data point and the last actual data point

    const p = {
      'terms': regInfo.terms, 
      'xShift': 0,
      'yShift': 0,
      'scale': params.scale,
      'degree': params.degree,
    }
    let maxYDiff = 0 // A small number
    let minYDiff = 1e20 // A large number
    const costs = []
    for (const [x,y] of Object.entries(data)) {
      // Get fractional equation
      const y_prediction = fracEquation(x,p)
      maxYDiff = Math.max(maxYDiff, (y_prediction - y) ** 2)
      minYDiff = Math.min(minYDiff, (y_prediction - y) ** 2)
      costs.push((y_prediction - y) ** 2)
    }

    // For each data point, [key,val]=[x,y] in data dictionary 
    // Optimization cost function is maximize
    const totalScore = Object.entries(data).reduce((costSum, [x,y], idx) => {
      // Return the total cost by summing all costs
      // Cost should be normalized before the multiplier

      return costSum - weights[graphType].relevance * (idx+1) * (normalize(costs[idx], 
                                                                  minYDiff, 
                                                                  maxYDiff)**2)
        - weights[graphType].scale * (normalize(params.scale - bias[graphType].scale, 
                                      searchSpaceInputs.scale.min, 
                                      searchSpaceInputs.scale.max)**2) 
        - weights[graphType].degree * (normalize(params.degree - bias[graphType].degree, 
                                      searchSpaceInputs.degree.min, 
                                      searchSpaceInputs.degree.max)**2) 
    }, 0) 
    return totalScore
  }

  const normalize = (value, min, max) => {
    return (value-min) / (max-min)
  }

  // Weights for cost function
  const weights = {
    [GRAPHTYPES.score]: {
      "relevance": 7,
      "scale": 1,
      "degree": 2,
      "yShift": 10
    },
    [GRAPHTYPES.watchers]: {
      "relevance": 6,
      "scale": 5,
      "degree": 3,
      "yShift": 2
    }
  }

  // Bias the cost function by creating an offset
  const bias = {
    [GRAPHTYPES.score]: {
      "scale": 1,
      "degree": 0.4,
      "yShift": 0
    },
    [GRAPHTYPES.watchers]: {
      "scale": 7,
      "degree": 0.4,
      "yShift": -6000
    }
  }

  const searchSpace = searchSpaceInputs

  // Initialize the optimizer
  const optimizer = new BayesianOptimizer({
    exploration: 0.1, // Optional, default is 0.01
    numCandidates: 100, // Optional, default is 100
    kernelNu: 1.5, // Optional, default is 1.5
    kernelLengthScale: 1.0, // Optional, default is 1.0
  })

  // Optimize the objective function
  await optimizer.optimize(objectiveFunction, searchSpace, 200)

  // Get the best parameters found
  const bestParams = optimizer.getBestParams()
  console.log(bestParams, 'best params')
  return bestParams
}
















