import { useEffect, useState } from 'react'
import {
  useNavigate,
  useLocation
} from "react-router-dom"
import {
  AppBar,
  Button,
  CircularProgress,
  Container, 
  Select,
  Table,
  Toolbar,
} from '@mui/material'
import { addDataIntoCache, getDataFromCache } from '../util/cacheInterface'
import { GRAPHTYPES, REGRESSIONTYPES } from './../util/enum'
import servers from '../data/servers'
import getTable from './../util/serverFunctions'
import { assert, formatToDate } from './../util/utilityFunctions'
import { Extrapolation } from './Extrapolation'
import { FalParameters } from './FalParameters'
import Strategy from './Strategy'
import { calcExtrapolation } from '../calculations/calcExtrapolation'
import { display } from '@mui/system'


const Predictions = () => {
  const [myTeam, setMyTeam] = useState({}) // Data with team id and name
  const [teamData, setTeamData] = useState([]) // Full data with points
  const [cacheData, setCacheData] = useState({})
  const [displayedShow, setDisplayedShow] = useState(-1)
  const [contents, setContents] = useState({})
  const [menu, setMenu] = useState(0)
  const [tunedAllShows, setTunedAllShow] = useState(false)
  const [nextUntunedShowId, setNextUntunedShowId] = useState(-1)
  const [extrapolatedData, setExtrapolatedData] = useState({})
  const [regressionInfos, setRegressionInfos] = useState({})
  const [regressionInfosLoaded, setRegressionInfosLoaded] = useState(false)
  const [falParameters, setFalParameters] = useState({
    'swapsUsed': 0, 
    'aced': {},
    'specialUsed': false
  })
  const [autotuning, setAutotuning] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const teamUrl = window.location.href.replace('predictions', 'team')
  const animeStatsTable = servers.anime_stats
  const animeTable = servers.anime
  const url = window.location.href

  // Get Database
  useEffect(() => {
    console.log("Welcome to predictions page")

    // Check cache for team
    const fetchData = async () => {
      const tempCacheData = await getDataFromCache('myFalTeam', teamUrl)

      if(tempCacheData !== undefined) {
        setMyTeam(tempCacheData)
        console.log('successfully loaded falParameters from cache')

        let tempFalParamCacheData = await getDataFromCache('falParameters', url)
        // Filter out shows that are not on the team
        const tempAced = Object.keys(tempCacheData).reduce((filtered, key) => {
          if(key in tempCacheData){
            filtered[key] = false 
            if(tempFalParamCacheData !== undefined && 
                key in tempFalParamCacheData.aced)
              {
                filtered[key] = tempFalParamCacheData.aced[key]
              }
          }
          return filtered
        }, {})

        tempFalParamCacheData = {...falParameters, 'aced':tempAced}
        setFalParameters(tempFalParamCacheData)

        const tempContents = []
        // const tempData = {} // Key is id
        for(let showId of Object.keys(tempCacheData)) {
          // Get multiple entries from anime_stats - query
          const data1 = await getTable(animeStatsTable, {"id": String(showId)})

          // Get one entry from anime - getItem
          const data2 = await getTable(animeTable, {"id": String(showId)})

          const d1 = JSON.parse(data1.body).Items
          const tempContent = {
            title: JSON.parse(data2.body).Item.name["S"],
            id: showId,
            stats: d1
          }
          tempContents[showId] = tempContent
        }

        setDisplayedShow(Object.keys(tempCacheData)[0])
        setContents(formatContent(tempContents, tempCacheData))
        setCacheData(tempCacheData)

        // dict = {id: {type: {}}}
        const createBlankDictionary = (idArray) => {
          const blankDict = {}
          idArray.forEach(showId => {
            blankDict[showId] = {}
            Object.keys(GRAPHTYPES).forEach(type => {
              blankDict[showId][type] = {}
            })
          })
          return blankDict
        }

        setRegressionInfos(
          createBlankDictionary(Object.keys(tempCacheData))
        ) //Set id only, no content
        setExtrapolatedData(
          createBlankDictionary(Object.keys(tempCacheData))
        )
      } else {
        setCacheData(undefined)
      }

      let tempCacheData2 = await getDataFromCache('myFalPredictions', url)
      
      // If falPrediction cache is undefined, create it 
      if(tempCacheData2 === undefined){
        addDataIntoCache('myFalPredictions', url, {})
        tempCacheData2 = {}
      }

      // Create a blank dictionary to add default regressionInfo
      const defaultDict = {}
      Object.keys(GRAPHTYPES).forEach(type => {
        defaultDict[type] = {}
      })

      // Find difference in key 
      Object.keys(tempCacheData).forEach(key => {
        if(tempCacheData2[key] === undefined){
          //Add in missing data
          tempCacheData2[key] = JSON.parse(JSON.stringify(defaultDict))
        }
      })

      if(tempCacheData2 !== undefined) {
        setRegressionInfos(tempCacheData2)
        checkTunedAllShows(tempCacheData2, Object.keys(tempCacheData))
      } 
      setRegressionInfosLoaded(true)
    }
    fetchData()
  },[])

  //! Note that the dictionary is not in date order.
  const formatContent = (tempContents, tempMyTeam) => {
    const tempFormattedContents = {} // [showId].stats[dates][type]
    Object.keys(tempMyTeam).forEach(showId => {
      tempFormattedContents[showId] = {}
      tempFormattedContents[showId].stats = {}
      tempFormattedContents[showId].dates = []
      tempFormattedContents[showId].title = tempContents[showId].title
      Object.values(tempContents[showId].stats).forEach(entry => {
        const d = formatToDate(entry.date.N)
        tempFormattedContents[showId].stats[d] = {}
        tempFormattedContents[showId].dates.push(d) // Dates only array
        Object.keys(GRAPHTYPES).forEach(type => {
          const tempType = type !== 'score' ? type : 'mean'
          tempFormattedContents[showId].stats[d][type] = 
            type === GRAPHTYPES.score 
              ? parseFloat(entry[tempType].N) 
              : parseInt(entry[tempType].N)
        })
      })
    })
    return tempFormattedContents
  }

  // Set variable to next show id that hasn't been extrapolated
  // Set 'tunedAllShows' variable to true when finished tuning
  const checkTunedAllShows = (data, showIds) => {
    let finishedTuning = true;
    // Contents has the shows on your team. RegressionInfo can have more.
    // So check through your team only in your data.
    let failedId = 0
    showIds.forEach(id => {
      if(!Number.isInteger(id)){
        id = parseInt(id)
      }
      // If tuned, watchers should never be empty
      if(Object.keys(data[id][GRAPHTYPES.watchers]).length === 0){
        failedId = id
        finishedTuning = false
        setNextUntunedShowId(id)
      }
    })
    setTunedAllShow(finishedTuning)
  }

  const handleRemainderExtrapolations = (e) => {
    e.preventDefault()
    addDataIntoCache('falParameters', url, falParameters)

    Object.keys(extrapolatedData).forEach(id => {
      // Identify which show has not been extrapolated yet
      if(Object.keys(extrapolatedData[id][GRAPHTYPES.watchers]).length === 0){
        Object.keys(GRAPHTYPES).map(type => {
          // Perform extrapolation calculations
          const [tempExtrapolatedData, isDecreasing] = 
            calcExtrapolation(type, contents[id], regressionInfos[id][type])
          // Store data
          handleExtrapolatedDataCallback(
            {show: id, type: type, info:tempExtrapolatedData}
          )
        })
      }
    })
    setMenu(2)
  }

  const handleFalParametersCallback = (data) => {
    setFalParameters(data)
  }

  const handleExtrapolatedDataCallback = (data) => {
    const copy = {...extrapolatedData}
    copy[data.show][data.type] = data.info[data.type]
    copy[data.show].dates = data.info.dates
    setExtrapolatedData(copy)
  }

  const handleRegressionInfoCallback = (data) => {
    if(Object.keys(regressionInfos).length > 0){
      const copy = {...regressionInfos}
      copy[displayedShow][data.type] = data.info
      setRegressionInfos(copy)
      checkTunedAllShows(copy, Object.keys(myTeam))
    }
  }

  const handleAutotuningCallback = (value) => {
    setAutotuning(value)
  }

  // Show tabs
  const showTabs = (
    <AppBar position="sticky" elevation={0} style={{top: "60px", backgroundColor: "#d6d6d6"}} >
      <Toolbar>
        {Object.keys(myTeam).length !== 0
          ? <div>
              {Object.keys(myTeam).map((showId, idx) => (
                <Button key={showId} color="inherit" 
                  style={showId === displayedShow ? 
                    { 
                      float: 'left',
                      backgroundColor: "#27b7e3",
                      margin: "10px",
                      padding: "6px"
                    } : {
                      float: 'left',
                      backgroundColor: "DodgerBlue",
                      margin: "10px",
                      padding: "6px"
                    }
                  }
                  onClick={() => {
                    setDisplayedShow(showId)
                    addDataIntoCache('myFalPredictions', url, regressionInfos)
                  }}
                >
                {idx+1}. {Object.keys(contents).length > 0 && contents[showId].title}
                </Button>
              ))}
              <div style={{ marginTop: '1.5rem' }}></div>
              { Object.keys(extrapolatedData).length !== 0 && 

              tunedAllShows 
              ? <Button color="inherit"
                  style={{float: 'left', 
                          fontSize: '20px',
                          backgroundColor: '#4CAF50',
                          fontWeight: 'bold',
                          margin: '5px'}}
                  onClick={() => {
                    setMenu(1)
                    addDataIntoCache('myFalPredictions', url, regressionInfos)
                  }}>
                  Go Next!
                </Button>
              : <Button color="inherit"
                  style={{float: 'left', 
                        fontSize: '20px',
                        backgroundColor: '#4CAF50',
                        fontWeight: 'bold'}}
                  onClick={() => {
                    setDisplayedShow(nextUntunedShowId)
                    addDataIntoCache('myFalPredictions', url, regressionInfos)
                  }}
                >
                Save and Tune Next Show
                </Button>
              }
            </div>
          : <div>Loading...</div>
        }
      </Toolbar>
    </AppBar>
  )

  // Graph type tabs
  const graphTypeTabs = (
    <div>
      {displayedShow !== -1 && 
      Object.keys(regressionInfos[displayedShow]).length > 0 &&
        regressionInfosLoaded &&
        Object.keys(GRAPHTYPES).map(type => (
          <div key={type} style={{ marginTop: '1.5rem' }}>
            {
              Object.keys(contents).length > 0  
              ? ( 
                <Extrapolation graphType={type} 
                  content={contents[displayedShow]} 
                  regressionInfoParam={regressionInfos[displayedShow][type]}
                  showId={displayedShow}
                  extrapolatedDataCallback=
                    {handleExtrapolatedDataCallback}
                  regressionInfoCallback=
                    {handleRegressionInfoCallback}
                  autotuningCallback=
                    {handleAutotuningCallback}
                />
              )
              : (<div>no contents</div>)
            }
          </div>
        ))
      }
    </div>
  )

  return (
    <Container >
      {/* {autotuning
      ? <div>
          sss
          {autotuning ? "true" : "false"}
          eee
          <br/>
          <CircularProgress />
          <br/>
          <h3>Calculating...</h3>
        </div>*/}
      <div> 
          <h1>Predictions</h1>
          {cacheData === undefined
            ? <div>
                <h1>Select a team before proceeding</h1>
                <Button style={{float: 'left'}}
                  onClick={() => {
                    navigate('/team')
                  }}>
                  Select My Team!
                </Button>
              </div>
            : Object.keys(cacheData).length === 0
              ? <div>Loading...</div>
              : <div>{(() => {
                switch(menu){
                  case 0: 
                    return (<div>
                      <h2>Step 1: Tune the predictions</h2>
                      {showTabs}
                      {graphTypeTabs}
                    </div>)
                  case 1:
                    return (<div>
                      <Button onClick={() => setMenu(0)}>
                        Go Back
                      </Button>
                      <h2>Step 2: Set parameters</h2>
                      <FalParameters contents={contents} 
                        falParameters={falParameters}
                        falParametersCallback=
                          {handleFalParametersCallback}/>
                      <Button 
                        onClick={handleRemainderExtrapolations}>
                        Predict!
                      </Button>
                    </div>)
                  case 2:
                    return(<div>
                      <h1>Predicted Results</h1>
                      <Button onClick={() => setMenu(0)}>
                        Change Settings
                      </Button>

                      <Strategy extrapolatedData={extrapolatedData}
                                myTeam={myTeam}
                                contents={contents}
                                falParameters={falParameters}/>
                    </div>)
                  default:
                    return (<div>Error: Bad Menu State</div>)
                }
              })()}</div>
          }
        </div>
    </Container>
  )
} 
export default Predictions