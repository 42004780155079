import { GRAPHTYPES } from './../util/enum'
import { convertToPoints, ruleDict, thisWeekNumber } from './../util/falRuleWrapper'
import { assert } from '../util/utilityFunctions'


// Parameters: Array of showIds, Dict of prediction[showId][type][week], same for true data
export const calcPoints = (showIds, data) => {
  // data[type][week]
  const output = {}
  showIds.forEach(showId => {
    output[showId] = {}
    for(let week = 1; week <= ruleDict.totalWeeks; week++) {
      const sum = Object.keys(GRAPHTYPES).reduce((pts, key) =>
        pts+convertToPoints(GRAPHTYPES[key], data[showId], week), 0)
      assert(typeof sum === 'number' && !isNaN(sum), `sum is not a number ${sum}`)
      output[showId][week] = sum
    }
  })
  return output
}