import {
  AppBar,
  Button,
  Container, 
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  setRef
} from '@mui/material'
import { ruleDict } from './../util/falRuleWrapper'


export const FalParameters = ({contents, 
                              falParameters, 
                              falParametersCallback}) => 
{
  console.log(falParameters, 'falParameters in falparam page')

  const handleSwapsUsed = (event) => {
    falParametersCallback({...falParameters, 
      'swapsUsed':event.target.value})
  }


  const handleAcedAlready = (event, showId) => {
    const tempAced = {...falParameters.aced, 
      [showId]:event.target.checked}
    falParametersCallback({...falParameters, 'aced':tempAced})
  }

  
  return (
    <div>
      <h3>Swaps Used</h3>
      <Select
        style={{minWidth:100}}
        labelId="selectSwapsUsedLabel"
        id="selectSwapsUsed"
        value={falParameters.swapsUsed}
        label="Swaps used"
        onChange={handleSwapsUsed}
      >
        {[...Array(ruleDict['totalSwaps']+1).keys()].map(i => 
            (<MenuItem value={i} key={'totalSwaps'+i}>{i}</MenuItem>)
          )
        }
      </Select>
      <h3>Aced Already</h3>
      <Grid container spacing={1} alignItems="center">
        { Object.keys(contents).map(showId => (
            <Grid item  sm={4} key={'grid'+showId}>
              <FormControlLabel key={'aced'+showId}
                label={contents[showId].title}
                control={<Checkbox checked={
                            falParameters.aced[showId] || false
                          } 
                          onChange={(e) => handleAcedAlready(e,showId)} />}
              />
            </Grid>
          ))
        }
      </Grid>
      <h3>Special Used</h3>
      <FormControlLabel control={<Checkbox  />} label="Special Used"/>
    </div>
  )
}