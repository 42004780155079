const options = (params) => ({
  method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json'
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', 
  body: JSON.stringify(params) 
})


const getTable = async (table, dict) => {
  try {
    const response = await fetch(table, options(dict))
    if(!response.ok) {
      console.error('yikes, response is not ok')
    }
    return await response.json()
  } catch (err) {
    console.error('errr')
  }
} 
export default getTable