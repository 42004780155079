import { GRAPHTYPES } from './../util/enum.js'
import falRulesS8 from "../config/falRulesS8.json"
import falRulesS7 from "../config/falRulesS7.json"
import falRulesS6 from "../config/falRulesS6.json"
import falRulesS5 from "../config/falRulesS5.json"
import falRulesS4 from "../config/falRulesS4.json"
import falRulesS3 from "../config/falRulesS3.json"
import falRulesS2 from "../config/falRulesS2.json"

import { assert, dateDiffInDays } from './../util/utilityFunctions'

//! Current season must be first in the dictionary
const allFalRules = {
  "spring 2024": falRulesS8,
  "winter 2024": falRulesS7,
  "fall 2023": falRulesS6,
  "summer 2023": falRulesS5,
  "spring 2023": falRulesS4,
  "fall 2022": falRulesS3,
  "spring 2022": falRulesS2
}

const falRules = allFalRules["spring 2024"]

const rules = falRules.rules
const baseRules = rules.pointsCriteria.base
const specialRules = rules.pointsCriteria.special
const totalWeeks = rules.general.sessionLength
const falStartDateDict = rules.general.start
const falAwardStartDateDict = rules.general.awardingDate.starting
const falAwardStartDict = rules.general.awardingDate
const falAwardStartDate = new Date(Date.UTC(falAwardStartDateDict.year,
                              falAwardStartDateDict.month-1,
                              falAwardStartDateDict.date,
                              falAwardStartDict.time.hour,
                              falAwardStartDict.time.min,
                              0))
// Notice that start time's hour and min are from award times
const falStartDate = new Date(Date.UTC(falStartDateDict.year,
                              falStartDateDict.month-1,
                              falStartDateDict.date,
                              falAwardStartDict.time.hour,
                              falAwardStartDict.time.min,
                              0))

const falAwardEndDate = new Date(falStartDate.getTime()+(13*7*24*60*60*1000))


export const ruleDict = {
  'teamSize':  rules.general.numOfActive + rules.general.numOfBench,
  'numOfActive': rules.general.numOfActive,
  'numOfBench': rules.general.numOfBench,
  'countedWeeks': baseRules.posts.countedWeeks,
  'specialStartWeek': rules.wildcard.availableStartingWeek,
  'totalSpecials': rules.wildcard.numWildcards,
  'totalSwaps': rules.activeBenchAndSwaps.totalSwaps,
  'totalWeeks': rules.general.sessionLength,
  'maxMembers': rules.aces.maxMembers,
  'bonusPoints': specialRules.wildcard.booster.cost,
  'extraSwapCost': specialRules.wildcard.extraSwap.cost,
  'acePoints': specialRules.ace.correct,
  'falAwardStartDate': falAwardStartDate,
  'startDate': rules.general.start,
  'season': rules.general.start.season,
  'year': rules.general.start.year,
  'seasonNum': rules.general.seasonNum,
  'falStartDate': falStartDate,
  'subtractedScore': baseRules.score.subtractedScore,
  'falAwardEndDate': falAwardEndDate,
  'allSeasons': Object.keys(allFalRules)
}

const getAllFalAwardDates = () => {
  const falAwardDates = []
  let firstAwardDate = new Date(ruleDict.falAwardStartDate)
  for(let i = 0; i < ruleDict.totalWeeks; i++){
    falAwardDates.push(new Date(firstAwardDate))
    firstAwardDate.setDate(firstAwardDate.getDate() + 7)
  }
  return falAwardDates
}


// Key is in mm/yy format except for a couple of special dates like 1/1
// Input is a set of Date objects
export const getAllFalAwardDatesDic = (firstDateObj) => {
  const dic = {}
  const falAwardDatesArr = getAllFalAwardDates()
  falAwardDatesArr.forEach((d, idx) => {
    // If the date is the first date on xLabel or 1/1, then put in year
    let str = (d.getMonth()+1)+ '/' + d.getDate()

    if((firstDateObj.getMonth() === d.getMonth() &&
      firstDateObj.getDate() === d.getDate() &&
      firstDateObj.getFullYear() === d.getFullYear()) 
      || (d.getMonth()+1 == 1 && d.getDate() == 1)){
      str += '/' + d.getFullYear()
    }
    dic[str] = idx+1
  })
  return dic
}


//! Used to round down for weekdiff, now round up
export const getWeekNumber = (someDate) => {
  const weekDiff = (someDate.getTime() - falStartDate.getTime())/(1000 * 3600 * 24 * 7)
  return Math.min(Math.max(Math.ceil(weekDiff), 0), rules.general.sessionLength)
}


const getCriteria = () => {
  const criteria = {}
  for(let week = 1; week <= totalWeeks; week++){
    criteria[week] = []
    Object.keys(GRAPHTYPES).forEach(type => {
      if(baseRules[type].weeks[week] != 0) {
        criteria[week].push(type)
      }
    })
  }
  return criteria
}


export const allFalAwardDates = getAllFalAwardDates()
export const allFalAwardDatesSet = new Set(allFalAwardDates)
export const thisWeekNumber = getWeekNumber(new Date())
export const criteria = getCriteria()


export const convertToPoints = (type, data, week) => {
  let points = 0
  if(week > totalWeeks){
    throw `week given is greater than total weeks: ${week}`
  }

  // Assumes week starts with 1 but the predictions array doesn't
  if(week < 1) {
    throw `week less than 1 ${week}`
  }
  let value = 0
  try{
    value = data[type][week] ?? 0
  } catch(e){
    assert(data, week, type)
  }

  // Convert week to string
  week = week.toString()

  switch(type) {
    case GRAPHTYPES.score:
      if(value === 0){
        break
      }
      const scoreRule = baseRules.score
      points = (value-scoreRule.subtractedScore)*
        scoreRule.weeks[week]
      break
    case GRAPHTYPES.watchers:
      points = value * baseRules.watchers.weeks[week]
      break
    case GRAPHTYPES.dropped:
      points = value * baseRules.dropped.weeks[week]
      break
    case GRAPHTYPES.favorites:
      points = value * baseRules.favorites.weeks[week]
      break
    case GRAPHTYPES.posts:
      const multiplier = baseRules.posts.weeks[week]
      if(multiplier === 0){
        break
      }

      // Get prev post weeks
      const prevPostsWeek = week - baseRules.posts.countedWeeks
      
      // FAL sets week 0 posts as zero points
      let prevValue = 0
      if(prevPostsWeek !== 0){
        prevValue = data[GRAPHTYPES.posts][prevPostsWeek]
      }

      // Subtract out current week's point from x week back's points
      points = (value-prevValue) * multiplier
      break
  }
  assert(!isNaN(points), 
  `points is nan  ${points}, ${value}, ${type}, ${week}`)

  return Math.round(points)
}