import { REGRESSIONTYPES, GRAPHTYPES } from '../util/enum'
import { ruleDict } from '../util/falRuleWrapper'
import { dateDiffInDays, 
        convertDatesToIndicies,
        formatToDate,
        assert,
        getDaysArray
       } from '../util/utilityFunctions'
import { fracEquation } from './regressionEquations'
import { config } from '../util/predictionConfigWrapper'
import PolyReg from "js-polynomial-regression"
import { bayesianOptimization } from './bayesianOptimization'

/*
  This script takes current content data and generates
  the parameters to best fit the fractional prediction
  line. Its essentially automating the process of a
  person tuning manually.
*/

// Function call should be PER GRAPH
// "content" is already filtered to a single show
export const autoTune = async (graphType, content, params) => 
{
  console.log('Welcome to AutoTune', content, params)

  // Cannot autotune if there are less than 3 data points
  if(content.dates.length < 3){
    console.log("Less than 3 data points. Cannot autotune")
    return {}
  }
  
  //Data will be formatted as follows:
  // data = {0:val1, 1:val2, ... N:valN} where key is index and value is points
  const dates = content.dates
  const data = {0 : content.stats[dates[0]][graphType]} // Put in first entry
  let prev = 0
  for(let i = 1; i < dates.length; i++){
    let diff = dateDiffInDays(dates[i-1], dates[i])
    data[prev+diff] = content.stats[dates[i]][graphType]
    prev += diff
  }

  const searchSpaceInputs = {
    // xShift: { min: config.minXShift, max: config.maxXShift },
    // yShift: { min: config.minYShift[2][graphType], max: config.maxYShift[2][graphType] },
    degree: { min: config.minDegree[2], max: config.maxDegree[2] },
    scale: { min: config.minScale[2], max: config.maxScale[2][graphType] },
  }
  const output = await bayesianOptimization(graphType, data, params, searchSpaceInputs)

  // Now, determine the best yShift value

  // Find the last date entry by finding the largest valued key
  let lastEntry = 0
  for (const [x,y] of Object.entries(data)) {
    lastEntry = Math.max(x, lastEntry)
  }
  const p = {
    'terms': params.terms, 
    'xShift': 0,
    'yShift': 0,
    'scale': output.scale,
    'degree': output.degree,
  }
  const y_prediction = fracEquation(lastEntry,p)
  const yShiftBounds = {
    'min' : config.minYShift[2][graphType], 
    'max' : config.maxYShift[2][graphType],
  }

  // Make sure the value is between the min and max yShiftBounds
  output["yShift"] = Math.max(yShiftBounds.min, 
                      Math.min(yShiftBounds.max, 
                        data[lastEntry] - y_prediction))

  // Rounding to 2 decimal places
  Object.entries(output).map(([key,val]) => {
    output[key] = Math.round(val*100)/100
  })
  return output
}

































