import { useEffect, useState } from 'react'
import {
  useNavigate,
} from "react-router-dom"
import {
  AppBar,
  Button,
  Container, 
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  setRef
} from '@mui/material'
import { GRAPHTYPES, REGRESSIONTYPES } from '../util/enum'
import { assert, 
          formatToDate, 
          compareDictionaries } from '../util/utilityFunctions'
import { calcExtrapolation,
          formatExtrapolatedData,
          filterZeroes
        } from '../calculations/calcExtrapolation'
import { autoTune} from '../calculations/autoTune'
import { config } from '../util/predictionConfigWrapper'
import Graph from './Graph'

export const Extrapolation = 
  ({graphType, content, regressionInfoParam, showId,
    extrapolatedDataCallback, regressionInfoCallback, autotuningCallback}) => 
{
  // Obtain previously edited regressionInfo parameters
  const defaultRegInfo = {'type':REGRESSIONTYPES.fractional,
                          'degree':config.defaultDegree[graphType],
                          'xShift':0, 'yShift':0, 
                          'scale': config.defaultScale[graphType],
                          'decreasing': false,
                          'finalXShift':0, 'finalYShift':0}
  const initRegInfo = Object.keys(regressionInfoParam).length === 0
                      ? {...defaultRegInfo}
                      : regressionInfoParam

  const [regressionInfo, setRegressionInfo] = useState(initRegInfo)
  const [refLine, setRefLine] = useState({})
  const [equationText, setEquationText] = useState([])
  const [uiBounds, setUiBounds] = useState({
    'minDegree': 0, 'maxDegree': 1, 'stepDegree': 0.01,
    'minXShift': 0, 'maxXShift': 1, 'stepX': 1,
    'minYShift': 0, 'maxYShift': 1, 'stepY': 1,
    'minScale': 0, 'maxScale': 1, 'stepScale': 1,
    'minFinalXShift': 0, 'maxFinalXShift': 1, 
    'minFinalYShift': 0, 'maxFinalYShift': 1
  })
  const [showEquation, setShowEquation] = useState(false)
  const [decreasingCheckbox, setDecreasingCheckbox] = useState(false) 
  const [params, setParams] = useState({})
  const [autotuning, setAutotuning] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    // Perform only once per graph
    console.log('welcome to Extrapolation.js', showId)
  },[])

  // If showId changed, get initRegInfo if regressionInfo is ...?
  useEffect(() => {
    // Change the UI to fit the stored data
    // If initRegInfo is default, set param to 0. Decreasing will not be overridden
    extrapolate(initRegInfo, 
                Object.keys(regressionInfoParam).length !== 0)

    const tempUIBounds = {...uiBounds}

    const pts = Math.min(Object.values(content['dates']).length, 2).toString() // Cap at 2
    tempUIBounds.stepDegree = config.stepDegree[pts]
    tempUIBounds.minDegree = config.minDegree[pts]
    tempUIBounds.maxDegree = config.maxDegree[pts]

    tempUIBounds.stepScale = config.stepScale[pts]
    tempUIBounds.minScale = config.minScale[pts]
    tempUIBounds.maxScale = config.maxScale[pts]

    tempUIBounds.stepY = config.stepY[pts]
    tempUIBounds.minYShift = config.minYShift[pts]
    tempUIBounds.maxYShift = config.maxYShift[pts]

    tempUIBounds.minXShift = config.minXShift
    tempUIBounds.maxXShift = config.maxXShift 
    tempUIBounds.minFinalXShift = config.minFinalXShift
    tempUIBounds.maxFinalXShift = config.maxFinalXShift
    tempUIBounds.minFinalYShift = config.minFinalYShift
    tempUIBounds.maxFinalYShift = config.maxFinalYShift

    setUiBounds(tempUIBounds)
  },[showId])

  // Function to run autoTune
  useEffect(() => {
    const doAutoTune = async () => {
      console.log('starting to tune')
      const newParams = await autoTune(graphType, content, params)
      extrapolate(newParams)
      setAutotuning(false) // After finishing, set to "off"
      autotuningCallback(false)
      console.log('tuning finished...')
    }
    console.log('loading...', autotuning)
    
    if(autotuning) {
      if(content.dates.length <= 0){
        alert('Not enough data points to autotune. (Needs greater than 3)')
      } else {
        console.log('tuning....')
        doAutoTune()
        console.log('finished....')
      }
    }
  }, [autotuning])

  const extrapolate = (update, isDataAvailable=true) => {
    const tempRegInfo = Object.keys(regressionInfoParam).length === 0 
                          ? {...initRegInfo}
                          : {...regressionInfoParam}

    // Copy over all updates into tempRegInfo
    Object.entries(update).map(([key, val]) => {
      tempRegInfo[key] = val
    })

    const [tempExtrapolatedData, isDecreasing, params] = 
      calcExtrapolation(graphType, 
                        content, 
                        {...tempRegInfo}, 
                        isDataAvailable
                      )   
    setDecreasingCheckbox(isDecreasing)
    tempRegInfo['decreasing'] = isDecreasing
    setRegressionInfo(tempRegInfo)
    regressionInfoCallback({type: graphType, info: tempRegInfo})
    setParams(params)

    // Get equation model parameters
    setEquationText(tempExtrapolatedData.equationText)
    assert(tempExtrapolatedData.dates.length > 0, 
      'tempExtrapolatedData is none Extrapolation.js 107')
    // Set variable in parent class
    extrapolatedDataCallback(
      {show: showId, type: graphType, info: tempExtrapolatedData})
    setRefLine(formatExtrapolatedData(tempExtrapolatedData, graphType, content))
  }

  const handleResetRegInfo = (e) => {
    e.preventDefault()
    extrapolate(defaultRegInfo)
  }

  const handleSliderDates = (e) => {
    e.preventDefault()
    extrapolate({'numUsedDates':Number(e.target.value)})
  }

  const handleSliderX = (e) => {
    e.preventDefault()
    extrapolate({'xShift':Number(e.target.value)})
  }

  const handleSliderY = (e) => {
    e.preventDefault()
    extrapolate({'yShift':Number(e.target.value)})
  }

  const handleSliderScale = (e) => {
    e.preventDefault()
    extrapolate({'scale':Number(e.target.value)})
  }

  const handleDegree = (e) => {
    e.preventDefault()
    let tempDegree = Number(e.target.value)
    tempDegree = Math.max(Math.min(tempDegree, uiBounds.maxDegree),
                                     uiBounds.minDegree)
    extrapolate({'degree':Number(tempDegree)})
  }

  const handleFinalWeekShiftX = (e) => {
    e.preventDefault()
    extrapolate({'finalXShift':Number(e.target.value)})
  }

  const handleFinalWeekShiftY = (e) => {
    e.preventDefault()
    extrapolate({'finalYShift':Number(e.target.value)})
  }

  const handleHelp = (e) => {
    e.preventDefault()
    navigate('/About')
  }

  const handleDecreasingCheckbox = (e) => {
    //! Must not have e.preventDefault() for checkbox!!
    extrapolate({'decreasing':e.target.checked})
  }

  const handleAutoTune = async (e) => {
    e.preventDefault()
    setAutotuning(true) // Activate the loading function
    autotuningCallback(true)
    console.log('handler has ran')
  }

  const buttonStyle = {
    margin: "5px",
    float: 'left', 
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'DodgerBlue'
  }

  return (
    <div>
      <AppBar position="static" sx={{bgcolor:"white", border: '2px solid black'}}  elevation={0} >
      <Toolbar>
      <div style={{ display: 'flex', color: "black"}} >
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography id="input-decreasing-checkbox-label" gutterBottom>
              Decreasing
            </Typography>
            <Checkbox 
              checked={decreasingCheckbox} 
              onChange={handleDecreasingCheckbox}
            />
          </Grid>
          <Grid item>
            {Object.keys(regressionInfo).length !== 0 && 
            <FormControl style={{minWidth: 100, maxWidth: 200}} 
              disabled={regressionInfo.type !== 
                        REGRESSIONTYPES.fractional}>
              <Typography id="input-slider-degree-label" gutterBottom>
                Long-term Growth
              </Typography>
              <Slider
                  value={regressionInfo.degree}
                  onChange={handleDegree}
                  aria-labelledby="input-degree"
                  style={{minWidth: 10, maxWidth: 200}} 
                  defaultValue={0}
                  step={uiBounds.stepDegree}
                  min={uiBounds.minDegree}
                  max={uiBounds.maxDegree}
                />
              <Input value={regressionInfo.degree} size="small"
                onChange={handleDegree}
                inputProps={{
                  step: uiBounds.stepDegree,
                  min: uiBounds.minDegree,
                  max: uiBounds.maxDegree,
                  type: 'number','aria-labelledby': 'input-slider',
                }}
              />
            </FormControl>
            }
          </Grid>
          <Grid item>
            <Typography id="input-slider-scale-label" gutterBottom>
              Initial Growth 
            </Typography>
            <Slider
              value={regressionInfo.scale}
              onChange={handleSliderScale}
              aria-labelledby="input-slider-scale"
              style={{minWidth: 10, maxWidth: 200}} 
              defaultValue={1}
              min={uiBounds.minScale}
              max={uiBounds.maxScale[graphType]} 
            />
            <Input value={regressionInfo.scale} size="small"
              onChange={handleSliderScale}
              inputProps={{
                step: uiBounds.stepScale, 
                min: uiBounds.minScale,
                max: uiBounds.maxScale[graphType],
                type: 'number', 'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
          <Grid item>
            <Typography id="input-slider-Y-label" gutterBottom>
              Move Vertical
            </Typography>
            <Slider
              value={regressionInfo.yShift}
              onChange={handleSliderY}
              aria-labelledby="input-slider-y"
              style={{minWidth: 10, maxWidth: 200}} 
              defaultValue={0}
              min={uiBounds.minYShift[graphType]} 
              max={uiBounds.maxYShift[graphType]} 
              step={uiBounds.stepY[graphType]}
            />
            <Input value={regressionInfo.yShift} size="small"
              onChange={handleSliderY}
              inputProps={{
                step: uiBounds.stepY[graphType], 
                min: uiBounds.minYShift[graphType], 
                max: uiBounds.maxYShift[graphType],
                type: 'number', 'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
          <Grid item>
              <Typography id="input-slider-X-label" gutterBottom>
                Move Horizontal
              </Typography>
              <Slider
                value={regressionInfo.xShift}
                onChange={handleSliderX}
                aria-labelledby="input-slider-x"
                style={{minWidth: 10, maxWidth: 200}} 
                defaultValue={0}
                min={uiBounds.minXShift}
                max={uiBounds.maxXShift} 
              />
              <Input value={regressionInfo.xShift} size="small"
                onChange={handleSliderX}
                inputProps={{step: uiBounds.stepX, 
                  min: uiBounds.minXShift,
                  max: uiBounds.maxXShift,
                  type: 'number', 'aria-labelledby': 'input-slider',
                }}
              />
          </Grid>
          <Grid item>
            <Typography id="input-slider-final-X-label" gutterBottom>
                Final Airing Date
              </Typography>
              <Slider
                value={regressionInfo.finalXShift}
                onChange={handleFinalWeekShiftX}
                aria-labelledby="input-slider-final-x"
                style={{minWidth: 10, maxWidth: 200}} 
                defaultValue={0}
                min={uiBounds.minFinalXShift}
                max={uiBounds.maxFinalXShift} 
              />
              <Input value={regressionInfo.finalXShift} size="small"
                onChange={handleFinalWeekShiftX}
                inputProps={{
                  step: uiBounds.stepX, 
                  min: uiBounds.minFinalXShift,
                  max: uiBounds.maxFinalXShift,
                  type: 'number', 'aria-labelledby': 'input-slider',
                }}
              />
          </Grid>
          <Grid item>
            <Typography id="input-slider-final-Y-label" gutterBottom>
                Final Airing Date Increase
              </Typography>
              <Slider
                value={regressionInfo.finalYShift}
                onChange={handleFinalWeekShiftY}
                aria-labelledby="input-slider-final-y"
                style={{minWidth: 10, maxWidth: 200}} 
                defaultValue={0}
                min={uiBounds.minFinalYShift[graphType]} 
                max={uiBounds.maxFinalYShift[graphType]} 
                step={uiBounds.stepY[graphType]}
              />
              <Input value={regressionInfo.finalYShift} size="small"
                onChange={handleFinalWeekShiftY}
                inputProps={{
                  step: uiBounds.stepY[graphType], 
                  min: uiBounds.minFinalYShift[graphType], 
                  max: uiBounds.maxFinalYShift[graphType],
                  type: 'number', 'aria-labelledby': 'input-slider',
                }}
              />
          </Grid>
          <Grid item>
            <Button style={buttonStyle}
                    onClick={handleResetRegInfo}>
              Reset
            </Button>
            {graphType === GRAPHTYPES.score || graphType === GRAPHTYPES.watchers
              ? <Button style={buttonStyle}
                    onClick={handleAutoTune}>
                Autotune
              </Button>
              : <div></div>
            }
          </Grid>
          <Grid item>
            {showEquation 
              ? <div><Button style={buttonStyle}
                      onClick={() => setShowEquation(false)}>
                  Hide Equation
                </Button>
                Equation: {equationText}
                </div>
              : <Button style={buttonStyle}
                      onClick={() => setShowEquation(true)}>
                Show Equation
              </Button>
            }
          </Grid>
          <Grid item>
            <Button style={buttonStyle}
                      onClick={handleHelp}>
                  Help
            </Button>
          </Grid>
        </Grid>
      </div>
      </Toolbar>
      </AppBar>
      {
        Object.keys(refLine).length !== 0 && 
          <Graph graphType={graphType} 
                content={
                  {...content, stats: 
                    Object.keys(content.stats).reduce((filtered, key) => {
                      filtered[key] = content.stats[key][graphType]
                      return filtered
                    }, {})
                  }
                }
                refLine={refLine} />
      }
    </div>
  )
}