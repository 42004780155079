import { REGRESSIONTYPES, GRAPHTYPES } from '../util/enum'
import { ruleDict } from '../util/falRuleWrapper'
import { dateDiffInDays, 
        convertDatesToIndicies,
        formatToDate,
        assert,
        getDaysArray
       } from '../util/utilityFunctions'
import { polyEquation, fracEquation } from './regressionEquations'
import { config } from '../util/predictionConfigWrapper'
import PolyReg from "js-polynomial-regression"


const applyThreshold = (extrapolatedData, graphType) => {
  // For now, deep copy just in case....
  const threshold = {}
  threshold[graphType] = []
  threshold['dates'] = extrapolatedData.dates
  threshold['equationText'] = extrapolatedData.equationText

  extrapolatedData[graphType].forEach(entry => {
    if(graphType === GRAPHTYPES.score){
      // Threshold between 1 and 10 for score graph
      // If entry is zero, push 0
      if(entry !== 0){
        threshold[graphType].push(
          Math.max(Math.min(entry, config.maxScore),
          config.minScore)
        )
      } else {
        threshold[graphType].push(0)
      }
    } else {
      // Threshold between 0 and inf for all other graph types
      threshold[graphType].push(Math.max(entry, 0))
    }
  })
  return threshold
}


// Must format data to use polyreg library
export const calcExtrapolation = 
  (graphType, content, regressionInfo, isDataAvailable=true) => 
{
  let isDecreasing = false

  // Get all FAL week dates
  let xDates = content.dates // Array of dates object
  const showStartDate = xDates[0] // First date object

  const falStartDate = ruleDict.falStartDate // Date object
  const falEndDate = new Date(falStartDate)
  falEndDate.setDate(falStartDate.getDate()+ruleDict.totalWeeks*7)

  let graphedDates = undefined
  if(showStartDate !== undefined) {
    // Decide if show or fal starts later
    const startDate = dateDiffInDays(showStartDate, falStartDate) > 0 
                      ? falStartDate : showStartDate
    graphedDates = getDaysArray(startDate, falEndDate)
    //! May cause problem if falEndDate === startDate...
  } else {
    graphedDates = getDaysArray(falStartDate, falEndDate)
    const prevDate = new Date()
    prevDate.setDate(prevDate.getDate()-1)
    xDates = [prevDate, new Date()] // Even though no points should show, we need ref line
  }

  // Prepare 'data' for polyReg 
  const data = []
  const xData = convertDatesToIndicies(xDates)
  Object.values(content.stats).forEach((entry, idx) => {
    const y = parseFloat(entry[graphType])
    if(y === 0) { return }
    assert(regressionInfo !== undefined, 
      `regressionInfo is undef ${graphType}`)
    data.push({x: xData[idx], y:y})
  })

  let terms = undefined
  if(data.length === 0){
  // If data has no points:
    terms = [graphType === GRAPHTYPES.score 
      ? config.defaultScore 
      : config.defaultValues,
    config.epsilon]
  } else if(data.length === 1){
  // If data only has one point:
    terms = [data[0].y, config.epsilon]
    // console.log('data has one point', data[0].y)
  } else {
    const model = PolyReg.read(data, 1) // Degree=1 for logarithmic
    terms = model.getTerms()

    // If data is available, then flip accordingly
    if(isDataAvailable){
      if(terms[1] < 0 && !regressionInfo.decreasing){
        terms[1] = Math.abs(terms[1])
      } else if(terms[1] > 0 && regressionInfo.decreasing){
        terms[1] = -Math.abs(terms[1])
      }
    }
    isDecreasing = terms[1] < 0

    // Bias the slope term. 
    //Should not be extremely small because user won't be able to edit slope.
    if(Math.abs(terms[1]) < config.minScoreDelta) {
      terms[1] = terms[1] > 0 ? config.minScoreDelta : -config.minScoreDelta
    }
  }

  const params = {
    terms: terms,
    scale: Number(regressionInfo.scale ?? 1), 
    degree: Number(regressionInfo.degree ?? -1), 
    yShift: Number(regressionInfo.yShift ?? 0), 
    xShift: Number(regressionInfo.xShift ?? 0),
    finalXShift: Number(regressionInfo.finalXShift ?? 0),
    finalYShift: Number(regressionInfo.finalYShift ?? 0)
  }

  const equationText = params.xShift > 0
                        ? `Y = ${(params.yShift + params.terms[0]).toFixed(3)} + 
                          ${(params.terms[1] * params.scale).toFixed(3)} * 
                          (X - ${params.xShift}) ^ ${params.degree}`
                        : `Y = ${(params.yShift + params.terms[0]).toFixed(3)} + 
                          ${(params.terms[1] * params.scale).toFixed(3)} * 
                          (X + ${-params.xShift}) ^ ${params.degree}`

  // Store predictions
  const predictedData = [] // idx is num of data
  const graphedDatesLength = graphedDates.length

  graphedDates.forEach((date, idx) => {
    let output = Math.max(fracEquation(idx, params), 0)
  
    // Sanity check
    assert(!isNaN(output) && Math.abs(output) !== Infinity,
      `calcExtrapolation.js. Output is ${output}. 
          Mostlikely, show only has one data point.`)

    // Change output for final week depending on finalXShift and finalYShift
    if(graphedDatesLength - 1 - idx <= params.finalXShift){
      output += params.finalYShift
    } 

    // Round each value
    predictedData.push(graphType === GRAPHTYPES.score
      ? Number(output.toFixed(2))
      : Math.round(output))
  })

  const extrapolatedData = {} 
  extrapolatedData[graphType] = predictedData
  extrapolatedData['dates'] = graphedDates
  extrapolatedData['equationText'] = equationText

  // Cap values between min and max
  return [applyThreshold(extrapolatedData, graphType), isDecreasing, params]
}


// Create a convolution of dates 
// Modify content and extrapolated data
export const formatExtrapolatedData = 
    (extrapolatedData, type, content) => 
  {     
    const extrapolatedDates = extrapolatedData.dates

    const needYear = 
      content.dates[0] !== undefined 
      ? extrapolatedDates[0] <= content.dates[0]
      : true

    const tempRefLine = {} // Key is type
    // Make sure each content point has a 
    // corresponding extrapolated date            
    const refLineData = []
    let prevDate = undefined
    extrapolatedDates.forEach((d, idx) => {
      let output = (d.getMonth()+1) + '/' + d.getDate()
      if((idx === 0 && needYear) || 
        (idx !== 0 && d.getFullYear() !== extrapolatedDates[idx-1].getFullYear()))
      {
        output += '/'+d.getFullYear()
      }

      refLineData.push({
        x: output, 
        y: extrapolatedData[type][idx]
      })
      prevDate = d
    })

    tempRefLine[type] = refLineData
    tempRefLine['dates'] = extrapolatedDates
    return tempRefLine
  }